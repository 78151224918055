import PartnerEarningsDialogTable from '@root/partners.admin.joinroot.com/src/components/partner-earnings-dialog-table';
import Pluralization from '@root/core/src/utils/pluralization';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useRef } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import ZIndex from '@root/core/src/utils/z-index';
import alertFilled from '@root/core/src/assets/alert-filled.svg';
import uniq from '@root/vendor/lodash/uniq';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function PartnerEarningsAdjustConfirmationModal({
  earningAdjustmentAmount,
  onCancel,
  onConfirm,
  partnerEarnings = [],
}) {
  const { trackClick } = useAnalytics('ADJUST_EARNINGS_CONFIRMATION', false);
  const portalRef = useRef();

  const handleCancel = () => {
    trackClick('CANCEL');
    onCancel();
  };

  const handleConfirm = () => {
    trackClick('APPLY');
    onConfirm();
  };

  const uniquePartnerCount = uniq(partnerEarnings.map((partnerEarning) => partnerEarning.partnerId)).length;
  const adjustmentConflictCount = partnerEarnings.filter((partnerEarning) => partnerEarning.earningAdjustment !== 0).length;

  return (
    <div css={styles.cardWrapper}>
      <div
        css={styles.outerContent}
        ref={portalRef}
      >
        <div css={styles.contentContainer}>
          <div css={styles.heading}>
            {adjustmentConflictCount > 0 ? (
              <>
                <div css={styles.headingText}>
                  Earning Adjustment Conflict
                </div>
                <img src={alertFilled} />
              </>
            ) :
              <div css={styles.headingText}>
                Earning Adjustment Creation
              </div>
            }
          </div>
          <div css={styles.message}>
            {adjustmentConflictCount > 0 ? (
              <>
                <span css={styles.caution}>Conflict. </span>
                <span>Changes you are requesting to make conflict with an existing earning adjustment.</span>
              </>
            ) :
              <span>You are requesting the creation of adjustments to the following partner earnings.</span>
            }
          </div>
          <div css={styles.summaryCounts}>
            <div
              css={styles.summaryCount}
              data-testid={'partner-summary-count'}
            >
              <span css={styles.count}>{uniquePartnerCount}</span>
              <span css={styles.countingText}>{Pluralization.pluralize(uniquePartnerCount, 'Partner', 'Partners')}</span>
            </div>
            {adjustmentConflictCount > 0 && (
              <div
                css={styles.summaryCount}
                data-testid={'conflict-summary-count'}
              >
                <span css={styles.conflictsCount}>{adjustmentConflictCount}</span>
                <span css={styles.countingText}>{Pluralization.pluralize(adjustmentConflictCount, 'Conflict', 'Conflicts')}</span>
              </div>
            )}
          </div>
          <div css={styles.tableContainer}>
            <PartnerEarningsDialogTable
              earningAdjustmentAmount={earningAdjustmentAmount}
              partnerEarnings={partnerEarnings}
            />
          </div>
          <div
            css={styles.spacing}
          />
          <div css={styles.buttonArea}>
            <div css={styles.buttons}>
              <div
                css={styles.cancel}
                onClick={handleCancel}
              >
                Cancel
              </div>
              <div css={styles.applyWrapper}>
                <PrimaryButton
                  css={styles.button}
                  onClick={handleConfirm}
                >
                  {'Apply Update'}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PartnerEarningsAdjustConfirmationModal.propTypes = {
  earningAdjustmentAmount: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  partnerEarnings: PropTypes.arrayOf(PropTypes.shape({
    accountCount: PropTypes.number,
    attributedEarnedEventCount: PropTypes.number,
    costPerProfile: PropTypes.number,
    earnedMonth: PropTypes.string.isRequired,
    earning: PropTypes.number.isRequired,
    earningAdjustment: PropTypes.number.isRequired,
    finalized: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    installCount: PropTypes.number,
    partnerId: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
    partnerType: PropTypes.string,
    partnerTypeId: PropTypes.string,
    policyCount: PropTypes.number,
    profileCount: PropTypes.number,
    profileToBindRatio: PropTypes.number,
    quoteViewedCount: PropTypes.number,
    rootQualityIndicator: PropTypes.number,
    testDriveCount: PropTypes.number,
    tier: PropTypes.number,
    totalEarning: PropTypes.number.isRequired,
  })).isRequired,
};

const styles = StyleSheet.create({
  summaryCounts: {
    display: 'flex',
    paddingBottom: 10,
  },
  summaryCount: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    color: Colors.gray50(),
  },
  count: {
    fontSize: 18,
    color: Colors.black(),
    fontWeight: 'bold',
    paddingRight: 55,
  },
  conflictsCount: {
    fontSize: 18,
    color: Colors.rootOrange(),
    fontWeight: 'bold',
  },
  countingText: {
    fontSize: 14,
    paddingRight: 17,
    marginTop: -5,
  },
  cardWrapper: {
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: ZIndex.MODAL,
    ...Responsive.lessThanSm({
      top: 48,
    }),
  },
  outerContent: {
    backgroundColor: Colors.white(),
    height: '90%',
    padding: '35px 30px 35px',
    borderRadius: 4,
    ...Responsive.lessThanSm({
      height: '100%',
    }),
    ...Responsive.lessThanLg({
      width: '75%',
    }),
    ...Shadows.cardShadow(),
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 990,
    margin: '0 auto',
  },
  heading: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'horizontal',
    marginBottom: 20,
  },
  headingText: {
    ...semiBold(),
    fontSize: 18,
    paddingRight: 10,
    color: Colors.nearBlack(),
  },
  message: {
    marginBottom: 20,
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '125%',
    color: Colors.gray50(),
  },
  caution: {
    ...semiBold(),
    color: Colors.rootOrange(),
  },
  tableContainer: {
    flexShrink: 1,
    overflowY: 'auto',
  },
  buttonArea: {
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
  },
  buttons: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    position: 'relative',
    zIndex: 200,
    padding: '6px 20px',
  },
  spacing: {
    flexGrow: 1,
  },
  cancel: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    marginRight: 30,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
      color: Colors.rootOrange(),
    },
  },
  applyWrapper: {
    ...Shadows.normal(),
  },
});
