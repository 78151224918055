import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const DashboardLayout = ({
  filters, table, rightPanel, tableHeader,
}) => (
  <div css={styles.contentSection}>
    <div css={styles.filtersContainer}>
      {filters}
    </div>
    <div css={styles.dataContainer}>
      {tableHeader}
      <div css={styles.mainTableContainer}>
        <div css={styles.table}>
          {table}
        </div>
      </div>
    </div>
    {rightPanel}
  </div>
);

DashboardLayout.propTypes = {
  filters: PropTypes.node,
  rightPanel: PropTypes.node,
  table: PropTypes.node,
  tableHeader: PropTypes.node,
};

export default DashboardLayout;

const styles = StyleSheet.create({
  contentSection: {
    display: 'flex',
    overflowX: 'auto',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  mainTableContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  filtersContainer: {
    paddingTop: '3px',
    borderRight: `1px solid ${Colors.gray20()}`,
    minWidth: 250,
    ...Responsive.lessThanSm({
      minWidth: 175,
    }),
    minHeight: 'calc(100vh - 118px)',
  },
  dataContainer: {
    flexGrow: 8,
  },
});
