import CaretLoader from '@root/core/src/components/caret-loader';
import DialogTable from '@root/partners.admin.joinroot.com/src/components/dialog-table';
import Pluralization from '@root/core/src/utils/pluralization';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo, useRef } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import ZIndex from '@root/core/src/utils/z-index';
import alertFilled from '@root/core/src/assets/alert-filled.svg';
import dayjs from '@root/vendor/dayjs';
import uniq from '@root/vendor/lodash/uniq';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useModal from '@root/core/src/hooks/use-modal';
import usePendingPricingRules from '@root/partners.admin.joinroot.com/src/hooks/use-pending-pricing-rules';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function PricingRuleChangeConfirmationModal({
  onCancel, onConfirm, activeRules, newRuleInformation,
}) {
  const { trackClick } = useAnalytics('PRICING_RULE_CHANGE', false);
  const portalRef = useRef();

  const combinations = useMemo(() => {
    return activeRules.map((r) => ({
      partner: r.partner.id,
      market: r.market,
    }));
  }, [activeRules]);

  const [pendingRules, isLoading] = usePendingPricingRules(combinations);

  const renderPortal = usePortal('pricing-rule-change-confirmation');
  useModal(onCancel, portalRef);

  const handleConfirm = () => {
    trackClick('APPLY_CHANGE');
    onConfirm();
  };

  const handleCancel = () => {
    trackClick('CANCEL');
    onCancel();
  };

  const newRules = activeRules.map((r) => ({
    effectiveDate: newRuleInformation.effectiveDate,
    market: r.market,
    partner: r.partner,
    pricingType: newRuleInformation.pricingType,
    events: newRuleInformation.events,
  }));

  const uniqueMarketCount = uniq(activeRules.map(({ market }) => market)).length;
  const uniquePartnerCount = uniq(activeRules.map(({ partner }) => partner.id)).length;

  return renderPortal(
    <div css={styles.cardWrapper}>
      <div
        css={styles.outerContent}
        ref={portalRef}
      >
        {isLoading &&
        <div css={styles.loadingContainer}>
          <CaretLoader />
        </div>
        }
        {!isLoading && (
          <div css={styles.contentContainer}>
            <div css={styles.heading}>
              {pendingRules.length ? (
                <>
                  <div css={styles.headingText}>
                    Pricing Conflict
                  </div>
                  <img src={alertFilled} />
                </>
              ) :
                <div css={styles.headingText}>
                  Pricing Change
                </div>
              }
            </div>
            <div css={styles.message}>
              {pendingRules.length ? (
                <>
                  <span css={styles.caution}>Conflict. </span>
                  <span>Changes you are requesting to make conflict with a pending rule.</span>
                </>
              ) :
                <>
                  <span css={styles.caution}>Caution. </span>
                  <span>Changes you are requesting to make conflict with an active rule.</span>
                </>
              }
              <br />
              <span>An update of your changes will be sent to Shereen Agrawal, VP of BD and Kumi Walker, CBO.</span>
              <br />
              <span>Changes will take effect {dayjs(newRuleInformation.effectiveDate).format('MMM D, YYYY')}</span>
            </div>
            <div css={styles.summaryCounts}>
              <div
                css={styles.summaryCount}
                data-testid={'partner-summary-count'}
              >
                <span css={styles.count}>{uniq(activeRules.map(({ partner }) => partner)).length}</span>
                <span css={styles.countingText}>{Pluralization.pluralize(uniquePartnerCount, 'Partner', 'Partners')}</span>
              </div>
              <div
                css={styles.summaryCount}
                data-testid={'market-summary-count'}
              >
                <span css={styles.count}>{uniqueMarketCount}</span>
                <span css={styles.countingText}>{Pluralization.pluralize(uniqueMarketCount, 'Market', 'Markets')}</span>
              </div>
              {!!pendingRules.length && (
                <div
                  css={styles.summaryCount}
                  data-testid={'conflict-summary-count'}
                >
                  <span css={styles.pendingRulesCount}>{pendingRules.length}</span>
                  <span css={styles.countingText}>{Pluralization.pluralize(pendingRules.length, 'Conflict', 'Conflicts')}</span>
                </div>
              )}
            </div>
            <div css={styles.tableContainer}>
              <DialogTable
                activeRules={activeRules}
                conflictingPendingRules={pendingRules}
                newRules={newRules}
              />
            </div>
            <div
              css={styles.spacing}
            />
            <div css={styles.buttonArea}>
              <div css={styles.buttons}>
                <div
                  css={styles.cancel}
                  onClick={handleCancel}
                >
                  Cancel
                </div>
                <div css={styles.applyWrapper}>
                  <PrimaryButton
                    css={styles.button}
                    onClick={handleConfirm}
                  >{'Override and apply update'}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  summaryCounts: {
    display: 'flex',
    paddingBottom: 10,
  },
  summaryCount: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    color: Colors.gray50(),
  },
  count: {
    fontSize: 18,
    color: Colors.black(),
    fontWeight: 'bold',
    paddingRight: 55,
  },
  pendingRulesCount: {
    fontSize: 18,
    color: Colors.rootOrange(),
    fontWeight: 'bold',
  },
  countingText: {
    fontSize: 14,
    paddingRight: 17,
    marginTop: -5,
  },
  cardWrapper: {
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: ZIndex.MODAL,
    ...Responsive.lessThanSm({
      top: 48,
    }),
  },
  outerContent: {
    backgroundColor: Colors.white(),
    height: '90%',
    padding: '35px 30px 35px',
    borderRadius: 4,
    ...Responsive.lessThanSm({
      height: '100%',
    }),
    ...Responsive.lessThanLg({
      width: '75%',
    }),
    ...Shadows.cardShadow(),
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 990,
    margin: '0 auto',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  heading: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'horizontal',
    marginBottom: 20,
  },
  headingText: {
    ...semiBold(),
    fontSize: 18,
    paddingRight: 10,
    color: Colors.nearBlack(),
  },
  message: {
    marginBottom: 20,
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '125%',
    color: Colors.gray50(),
  },
  caution: {
    ...semiBold(),
    color: Colors.rootOrange(),
  },
  tableContainer: {
    flexShrink: 1,
    overflowY: 'auto',
  },
  headingRow: {
    padding: 10,
    width: '100%',
    display: 'flex',
    borderRight: `1px solid ${Colors.gray10()}`,
    backgroundColor: Colors.nearWhite(),
    alignItems: 'left',
    justifyContent: 'left',
    ...Theme.paragraph2(),
  },
  buttonArea: {
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
  },
  buttons: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    position: 'relative',
    zIndex: 200,
    padding: '6px 20px',
  },
  spacing: {
    flexGrow: 1,
  },
  cancel: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    marginRight: 30,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
      color: Colors.rootOrange(),
    },
  },
  applyWrapper: {
    ...Shadows.normal(),
  },
});

PricingRuleChangeConfirmationModal.propTypes = {
  activeRules: PropTypes.array.isRequired,
  newRuleInformation: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
