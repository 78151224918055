import PropTypes from '@root/vendor/prop-types';

export default function PlusIconOrange({ iconStyles = {} }) {
  return (
    <svg
      css={iconStyles}
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle
        cx={'12'}
        cy={'12'}
        fill={'#FF5715'}
        r={'11'}
      />
      <path
        d={'M12 7V17'}
        stroke={'white'}
        strokeWidth={'2'}
      />
      <path
        d={'M17 12L7 12'}
        stroke={'white'}
        strokeWidth={'2'}
      />
    </svg>
  );
}

PlusIconOrange.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};
