import getTriageRulesConfiguration from '@root/partners.admin.joinroot.com/src/api/get-triage-rules/get-triage-rules-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useCallback, useEffect, useState } from '@root/vendor/react';

export default function useTriageRules() {
  const [triageRules, setTriageRules] = useState([]);

  const [request, isLoading] = useImperativeNetworkRequest(getTriageRulesConfiguration);

  const refresh = useCallback(async () => {
    const result = await request();
    if (result.isSuccess()) {
      result.data.triageRules.sort((a, b) => a.id > b.id ? 1 : -1);
      setTriageRules(result.data.triageRules);
    }
  }, [request]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [triageRules, refresh, isLoading];
}
