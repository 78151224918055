import Button from '@root/core/src/components/button';
import Colors from '@root/brand/src/utils/colors';
import Datepicker from '@root/core/src/components/datepicker';
import Input from '@root/core/src/components/input';
import PartnerTiers from '@root/partners.admin.joinroot.com/src/models/partner-tiers';
import PartnerTypes from '@root/partners.admin.joinroot.com/src/models/partner-types';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Select from '@root/core/src/components/select/select';
import dayjs from '@root/vendor/dayjs';
import useForm from '@root/core/src/hooks/use-form';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isRequired } from '@root/core/src/utils/validators';

const validations = [
  isRequired('name', 'Required field'),
  isRequired('hubspot', 'Required field'),
  isRequired('tier', 'Required field'),
  isRequired('type', 'Required field'),
  isRequired('payOnSuspicious', 'Required field'),
  isRequired('payOnDuplicate', 'Required field'),
  isRequired('payoutClassification', 'Required field'),
];

export default function PartnerForm({
  onConfirm, partner,
}) {
  const form = useForm({
    validations,
    initialValues: {
      name: partner?.name,
      rqi: partner?.maxRootQualityIndicator?.toString() || '1.5',
      earningsFinalizationDay: partner?.earningsFinalizationDay,
      hubspot: partner?.hubspotCompanyId,
      tier: partner?.tier?.toString(),
      type: partner?.partnerType?.name,
      payOnSuspicious: partner?.payOnSuspicious?.toString(),
      payOnDuplicate: partner?.payOnDuplicate?.toString(),
      payoutClassification: partner?.partnerPayoutClassifications.find((classification) => classification['active?'])?.classification,
    },
  });

  const [mediaSources, setMediaSources] = useState(() => {
    return partner?.partnerMediaSources?.map((ms) => ms.mediaSource)?.join(', ') || '';
  });

  const [classificationEffectiveDate, setClassificationEffectiveDate] = useState(partner ? dayjs().add(1, 'day').toDate() : dayjs().toDate());

  const handleDateChange = (date) => {
    setClassificationEffectiveDate(date);
  };

  const makeRequestAndCallSubmit = async (values) => {
    const request = {
      id: partner?.id,
      tier: parseInt(values['tier']),
      max_rqi: parseFloat(values['rqi']),
      pay_on_suspicious: values['payOnSuspicious'] === 'true',
      pay_on_duplicate: values['payOnDuplicate'] === 'true',
      partner_type_name: values['type'],
      hubspot_company_id: values['hubspot'],
      payout_classification: values['payoutClassification'],
      classification_effective_date: dayjs(classificationEffectiveDate).format('MMM D, YYYY'),
      earningsFinalizationDay: parseInt(values['earningsFinalizationDay']),
    };

    if (partner === null || partner === undefined) {
      request['media_sources'] = mediaSources.split(', ');
      request['partner_name'] = values['name'];
    } else {
      request['new_media_sources'] = mediaSources.split(', ');

      if (values['name'] !== partner?.name) {
        request['partner_name'] = values['name'];
      }
    }

    onConfirm(request);
  };

  function addMediaSource() {
    if (form.getFieldProps('mediaSource').value === '') {
      return;
    }

    if (mediaSources === '') {
      setMediaSources(form.getFieldProps('mediaSource').value);
    } else {
      setMediaSources(mediaSources + ', ' + form.getFieldProps('mediaSource').value);
    }
  }

  return (
    <div css={styles.formContainer}>
      <form onSubmit={form.createSubmitHandler(makeRequestAndCallSubmit)}>
        <fieldset css={styles.fieldset}>
          <Input
            label={'Partner Name'}
            wrapperStyle={styles.inputBase}
            {...form.getFieldProps('name')}
          />
          <div css={styles.formRow}>
            <div css={styles.rowInput}>
              <Input
                inputType={'number'}
                label={'Max RQI'}
                wrapperStyle={styles.inputBase}
                {...form.getFieldProps('rqi')}
              />
            </div>
            <div css={styles.rowInput}>
              <Input
                label={'Hubspot Company ID'}
                wrapperStyle={styles.inputBase}
                {...form.getFieldProps('hubspot')}
              />
            </div>
          </div>
          <div css={styles.formRow}>
            <div css={[styles.inputBase, styles.rowInput]}>
              <Select
                inputId={'tier'}
                inputName={'tier'}
                options={PartnerTiers.Tiers}
                placeholder={'Partner Tier'}
                selectBoxStyles={styles.selectBoxStyles}
                selectedValue={form.values.tier || ''}
                {...form.getFieldProps('tier')}
              />
            </div>
            <div css={[styles.inputBase, styles.rowInput]}>
              <Select
                inputId={'type'}
                inputName={'type'}
                options={PartnerTypes.Types}
                placeholder={'Partner Type'}
                selectBoxStyles={styles.selectBoxStyles}
                selectedValue={form.values.type || ''}
                {...form.getFieldProps('type')}
              />
            </div>
          </div>
          <div css={styles.formRow}>
            <div css={[styles.inputBase, styles.rowInput]}>
              <Select
                inputId={'payOnSuspicious'}
                inputName={'payOnSuspicious'}
                options={[{
                  label: 'True',
                  value: 'true',
                }, {
                  label: 'False',
                  value: 'false',
                }]}
                placeholder={'Pay On Suspicious'}
                selectBoxStyles={styles.selectBoxStyles}
                selectedValue={form.values.payOnSuspicious || ''}
                {...form.getFieldProps('payOnSuspicious')}
              />
            </div>
            <div css={[styles.inputBase, styles.rowInput]}>
              <Select
                inputId={'payOnDuplicate'}
                inputName={'payOnDuplicate'}
                options={[{
                  label: 'True',
                  value: 'true',
                }, {
                  label: 'False',
                  value: 'false',
                }]}
                placeholder={'Pay On Duplicates'}
                selectBoxStyles={styles.selectBoxStyles}
                selectedValue={form.values.payOnDuplicate || ''}
                {...form.getFieldProps('payOnDuplicate')}
              />
            </div>
          </div>
          <div css={[styles.inputBase, styles.rowInput]}>
            {partner && 'Change payout classification and assign effective date for new classification:'}
          </div>
          <div css={styles.formRow}>
            <div css={[styles.inputBase, styles.rowInput]}>
              <Select
                inputId={'payoutClassification'}
                inputName={'payoutClassification'}
                label={'Payout Classification'}
                options={[{
                  label: 'Referral Partner',
                  value: 'referral_partner',
                }, {
                  label: 'Appointed Independent Agent',
                  value: 'appointed_independent_agent',
                }]}
                placeholder={'Payout Classification'}
                selectBoxStyles={styles.selectBoxStyles}
                selectedValue={form.values.payoutClassification || ''}
                {...form.getFieldProps('payoutClassification')}
              />
            </div>
            {partner && (
              <div css={[styles.inputBase, styles.rowInput]}>
                <Datepicker
                  disablePastDays={true}
                  inputId={'start-date'}
                  label={''}
                  onDateChange={handleDateChange}
                  selectedDay={classificationEffectiveDate}
                  today={dayjs().add(1, 'day').toDate()}
                  value={classificationEffectiveDate}
                />
              </div>
            )}
          </div>
          <div css={styles.formRow}>
            <div css={styles.rowInput}>
              <Input
                label={'Earnings Finalization Day'}
                wrapperStyle={styles.inputBase}
                {...form.getFieldProps('earningsFinalizationDay')}
              />
            </div>
          </div>
          {'Media Sources: ' + mediaSources}
          <Input
            label={'Media Source'}
            wrapperStyle={styles.inputBase}
            {...form.getFieldProps('mediaSource')}
          />
          <Button
            onClick={addMediaSource}
            testID={'info-request-media-source-button'}
            type={'button'}
          >
            {'Add Media Source'}
          </Button>

          <Button
            disabled={mediaSources.length === 0 || !form.isValid || form.submitting || form.submitted}
            testID={'info-request-form-submit-button'}
            type={'submit'}
          >
            {form.submitted ? 'Thanks for submitting' : 'Submit'}
          </Button>
        </fieldset>
      </form >
    </div>
  );
}

const styles = StyleSheet.create({
  formContainer: {
    ...Responsive.lg({
      maxWidth: 400,
    }),
  },
  fieldset: {
    border: 'none',
    width: 400,
  },
  legend: {
    padding: 0,
  },
  formHeadline: {
    color: Colors.nearWhite(),
    fontSize: 25,
    lineHeight: '1.15',
  },
  required: {
    color: Colors.rootOrange(),
    marginBottom: 6,
  },
  inputBase: {
    flexGrow: 1,
    width: 'auto',
    marginTop: 10,
  },
  textArea: {
    marginTop: 10,
    height: 130,
  },
  formRow: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 -5px',

    ...Responsive.sm({
      flexDirection: 'row',
    }),
  },
  rowInput: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  selectBoxWrapper: {
    '& > div > div': {
      height: 'auto',
      '& > div:first-of-type': {
        top: 35,
      },
      '& > span': {
        fontSize: 16,
      },
    },
  },
  selectBoxStyles: {
    flex: 1,
    '::placeholder': {
      color: Colors.gray50(),
      opacity: 1,
    },
  },
  textAreaStyles: {
    '::placeholder': {
      color: Colors.gray50(),
      opacity: 1,
    },
  },
  formSubmitErrorMessage: {
    color: Colors.red(),
    margin: '6px 0 20px',
    fontSize: 16,
    lineHeight: 1.25,

    '& > a': {
      color: Colors.white(),
    },
  },
});

PartnerForm.propTypes = {
  addPartner: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  partner: PropTypes.object,
};
