import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useMemo, useState } from '@root/vendor/react';
import Table, { FILTER_TYPES } from '@root/internal-dashboards-core/src/components/table';
import infoIcon from '@root/partners.admin.joinroot.com/src/assets/icons/info-icon.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function PartnersTable({
  checkboxes = true,
  onRowSelect,
  partnerFilters,
  partners,
}) {
  const [filters, setFilters] = useState([]);

  const formatMediaSources = (mediaSources) => {
    return mediaSources.map((mediaSource) => mediaSource.mediaSource).join(', ');
  };

  const data = useMemo(() => {
    return partners.map((partner) => {
      return {
        partnerId: partner.id,
        partnerName: partner.name,
        partnerType: partner.partnerType.name,
        partnerTypeId: partner.partnerType.id,
        tier: partner.tier,
        hubspotCompanyId: partner.hubspotCompanyId,
        mediaSources: formatMediaSources(partner.partnerMediaSources),
        payOnDuplicate: partner.payOnDuplicate?.toString(),
        payOnSuspicious: partner.payOnSuspicious.toString(),
        maxRqi: partner.maxRootQualityIndicator,
        deactivatedAt: partner.deactivatedAt,
      };
    });
  }, [partners]);

  useEffect(() => {
    setFilters(partnerFilters
      .filter((x) => x.value?.length)
      .map((filter) => {
        return {
          ...filter,
          type: FILTER_TYPES.INCLUDES_SOME,
        };
      }));
  }, [partnerFilters]);

  const columns = useMemo(() => {
    const columnsNames = [
      {
        Header: 'Partner Id',
        accessor: 'partnerId',
      }, {
        Header: 'Partner',
        accessor: 'partnerName',
      }, {
        Header: 'Type',
        accessor: 'partnerType',
      }, {
        Header: 'Partner Type Id',
        accessor: 'partnerTypeId',
      }, {
        Header: 'Tier',
        accessor: 'tier',
      }, {
        Header: 'Hubspot Company Id',
        accessor: 'hubspotCompanyId',
      }, {
        Header: 'Media Sources',
        accessor: 'mediaSources',
      }, {
        Header: 'Pay on Duplicate',
        accessor: 'payOnDuplicate',
      }, {
        Header: 'Pay on Suspicious',
        accessor: 'payOnSuspicious',
      }, {
        Header: 'Max RQI',
        accessor: 'maxRqi',
      }, {
        Header: 'Deactivated At',
        accessor: 'deactivatedAt',
      }];

    return columnsNames;
  }, []);

  const hiddenColumns = [
    'partnerId',
    'partnerTypeId',
  ];

  return (
    <div css={styles.container}>
      {partners.length !== 0 && (
        <Table
          columns={columns}
          data={data}
          filters={filters}
          initialState={{
            hiddenColumns,
          }}
          onRowSelect={onRowSelect}
          selectable={checkboxes}
          styleOverrides={tableStyles}
          useVirtualRows={true}
        />
      )
      }

      {partners.length === 0 && (
        <div css={styles.noPartners}>
          <img
            alt={'info icon'}
            css={styles.infoIcon}
            src={infoIcon}
          />
          0 results found based on your filter criteria
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  noPartners: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: Colors.white(),
  },
  infoIcon: {
    paddingRight: 15,
    height: 25,
  },
});
const tableStyles = StyleSheet.create({
  tableHeaderGroup: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

PartnersTable.propTypes = {
  checkboxes: PropTypes.bool,
  onRowSelect: PropTypes.func.isRequired,
  partnerFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
  })),
  partners: PropTypes.array,
};

export default React.memo(PartnersTable);
