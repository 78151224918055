
import FilterList from '@root/core/src/components/filter-list';
import Input, { TextTypes } from '@root/core/src/components/input';
import PlusIconOrange from '@root/core/src/assets/plus-icon-orange';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Shadows from '@root/brand/src/utils/shadows';
import ZIndex from '@root/core/src/utils/z-index';
import useEscOrClickAwayToClose from '@root/internal-dashboards-core/src/hooks/use-esc-or-click-away-to-close';
import useForm from '@root/core/src/hooks/use-form';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';

const validations = [
  isValidEmail('email'),
  isRequired('email'),
];

export default function AddUser({
  activePartners,
  isLoading,
  onSubmit,
}) {
  const [selectedPartner, setSelectedPartner] = useState({
    value: '',
    label: '',
  });

  const form = useForm({
    validations,
    initialValues: {
      email: '',
      partnerId: selectedPartner.value,
    },
  });

  const onChangePartner = (option) => {
    setSelectedPartner(option);
  };

  const {
    ref, isComponentVisible, setIsComponentVisible,
  } = useEscOrClickAwayToClose();

  const handleButtonClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleSubmit = async () => {
    await onSubmit({
      email: form.getFieldProps('email').value,
      partnerId: selectedPartner.value,
    });

    setIsComponentVisible(false);
  };

  return (
    <div
      css={styles.container}
      ref={ref}
    >
      <button
        css={styles.button}
        onClick={() => handleButtonClick()}
      > {isLoading ? 'Loading' : 'Add User'}
        <PlusIconOrange />
      </button>
      <div
        css={!isLoading && isComponentVisible ? styles.displayed : styles.hidden}
        data-testid={'add-users'}
      >
        <div css={styles.inputField}>
          <Input
            {...form.getFieldProps('email')}
            focusOnMount={true}
            id={'email'}
            inputType={TextTypes.EMAIL}
            label={'Email'}
          />
        </div>
        <div css={styles.inputField}>
          <FilterList
            errorLabel={'We couldn’t find a partner with that name.'}
            id={'partnerId'}
            inputLabel={'Partner'}
            inputValue={selectedPartner.label}
            onChange={onChangePartner}
            onSelectOption={onChangePartner}
            options={activePartners}
          />
        </div>
        <PrimaryButton
          disabled={!selectedPartner.value || !form.isValid}
          onClick={handleSubmit}
        >
          Submit
        </PrimaryButton>
      </div>
    </div>
  );
}

AddUser.propTypes = {
  activePartners: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Root Sans',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    position: 'relative',
  },
  button: {
    color: Colors.rootOrange(),
    fontSize: 14,
    width: 175,
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${Colors.rootOrange()}`,
    backgroundColor: Colors.white(),
    cursor: 'pointer',
    padding: 7,
    ':focus': {
      outline: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
  displayed: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 40,
    padding: 10,
    backgroundColor: Colors.white(),
    boxShadow: Shadows.hoverShadow().boxShadow,
    borderRadius: 2,
    width: 350,
    zIndex: ZIndex.MODAL_OVERLAY_BACKGROUND,
  },
  inputField: {
    margin: '5px 0',
  },
  disabled: {
    textTransform: 'uppercase',
    fontSize: 10,
    backgroundColor: Colors.gray20(),
    borderRadius: 2,
    paddingLeft: 4,
    paddingRight: 4,
    color: Colors.gray50(),
  },
});
