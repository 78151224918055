import DashboardLayout from '@root/partners.admin.joinroot.com/src/components/dashboard-layout';
import HeaderLayout from '@root/partners.admin.joinroot.com/src/components/header-layout';
import Loading from '@root/partners.admin.joinroot.com/src/components/loading';
import PartnerAdminWrapper from '@root/partners.admin.joinroot.com/src/components/partners-admin-wrapper';
import PartnersAndMarketsContext from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context';
import PricingRuleChangeConfirmationModal from '@root/partners.admin.joinroot.com/src/components/pricing-rule-change-confirmation-modal';
import PricingRuleCreate from '@root/partners.admin.joinroot.com/src/components/pricing-rule-create';
import PricingRulesTable from '@root/partners.admin.joinroot.com/src/components/pricing-rules-table';
import React, { useCallback, useContext, useState } from '@root/vendor/react';
import RuleFilters from '@root/partners.admin.joinroot.com/src/components/rule-filters';
import Toast, { TOAST_TYPES } from '@root/partners.admin.joinroot.com/src/components/toast';
import upsertPricingRulesConfiguration from '@root/partners.admin.joinroot.com/src/api/upsert-pricing-rules/upsert-pricing-rules-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import usePricingRules from '@root/partners.admin.joinroot.com/src/hooks/use-pricing-rules';
import { RootError } from '@root-common/root-errors';

export default function Pricing() {
  const {
    supportedMarkets, partners, partnerTypes, networkCallsPending,
  } = useContext(PartnersAndMarketsContext);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [pricingRules, isLoadingPricingRules, refreshPricingRules] = usePricingRules();
  const [selectedPricingRules, setSelectedPricingRules] = useState([]);
  const [newRuleInformation, setNewRuleInformation] = useState(null);
  const [upsertPricingRulesRequest, isUpserting] = useImperativeNetworkRequest(upsertPricingRulesConfiguration);
  const [toastMessage, setToastMessage] = useState(null);

  const handleRowSelect = useCallback((selectedRows) => {
    const rules = selectedRows.map((row) => {
      const matchingRule = pricingRules.find((rule) => rule.market === row.marketId && rule.partner.id === row.partnerId);
      if (!matchingRule) {
        throw new RootError({
          message: `Could not find pricing rule with market ${row.marketId} and partner ${row.partnerId}`,
          name: 'PricingError',
          fingerprint: ['PricingNotFoundPricingRule'],
        });
      }

      return matchingRule;
    });

    setSelectedPricingRules(rules);
  }, [pricingRules]);

  const onModalConfirm = async () => {
    const result = await upsertPricingRulesRequest({
      partnerMarkets: selectedPricingRules.map((rule) => ({
        partnerId: rule.partner.id,
        market: rule.market,
      })),
      pricingType: newRuleInformation.pricingType,
      effectiveDate: newRuleInformation.effectiveDate,
      paymentEvents: newRuleInformation.events,
    });

    setNewRuleInformation(null);

    if (result.isSuccess()) {
      await refreshPricingRules();
    }

    setToastMessage({
      message: result.isSuccess() ? 'Pricing rules updated successfully' : 'There was an error updating pricing rules',
      title: result.isSuccess() ? 'Success' : 'Error',
      type: result.isSuccess() ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
    });
  };

  const displayLoader = isLoadingPricingRules || isUpserting || networkCallsPending;

  return (
    <PartnerAdminWrapper>
      <HeaderLayout title={'PRICING RULES'} />
      {
        !displayLoader && (
          <DashboardLayout
            filters={
              <RuleFilters
                markets={supportedMarkets}
                onChange={setSelectedFilters}
                partners={partners}
                partnerTypes={partnerTypes}
              />
            }
            table={
              <PricingRulesTable
                onRowSelect={handleRowSelect}
                pricingRuleFilters={selectedFilters}
                pricingRules={pricingRules}
              />
            }
            tableHeader={
              selectedPricingRules.length > 0 && <PricingRuleCreate onApplyRules={setNewRuleInformation} />
            }
          />)
      }

      {
        !!newRuleInformation &&
          <PricingRuleChangeConfirmationModal
            activeRules={selectedPricingRules}
            newRuleInformation={newRuleInformation}
            onCancel={() => setNewRuleInformation(null)}
            onConfirm={onModalConfirm}
          />
      }

      {displayLoader && <Loading />}

      <Toast
        analyticsContext={'PRICING'}
        toastMessage={toastMessage}
      />
    </PartnerAdminWrapper>
  );
}
