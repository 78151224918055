import AddUser from '@root/partners.admin.joinroot.com/src/components/add-user';
import DashboardLayout from '@root/partners.admin.joinroot.com/src/components/dashboard-layout';
import HeaderLayout from '@root/partners.admin.joinroot.com/src/components/header-layout';
import Loading from '@root/partners.admin.joinroot.com/src/components/loading';
import PartnerUserDeactivate from '@root/partners.admin.joinroot.com/src/components/partner-user-deactivate';
import PartnerUserDeactivationConfirmationModal from '@root/partners.admin.joinroot.com/src/components/partner-user-deactivation-confirmation-modal';
import PartnersAdminWrapper from '@root/partners.admin.joinroot.com/src/components/partners-admin-wrapper';
import PartnersAndMarketsContext from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context';
import React, { useCallback, useContext, useMemo, useState } from '@root/vendor/react';
import RuleFilters from '@root/partners.admin.joinroot.com/src/components/rule-filters';
import Toast, { errorToast, successToast } from '@root/partners.admin.joinroot.com/src/components/toast';
import UsersTable from '@root/partners.admin.joinroot.com/src/components/users-table';
import addUserConfiguration from '@root/partners.admin.joinroot.com/src/api/users/add-user-configuration';
import deactivatePartnerUsersConfiguration from '@root/partners.admin.joinroot.com/src/api/users/deactivate-partner-users-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useUsers from '@root/partners.admin.joinroot.com/src/hooks/use-users';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function UsersWrapper() {
  return (
    <PartnersAdminWrapper>
      <HeaderLayout title={'USERS'} />
      <Users />
    </PartnersAdminWrapper>
  );
}

function Users() {
  const {
    partnerTypes, partners, networkCallsPending,
  } = useContext(PartnersAndMarketsContext);
  const [users, isLoadingUsers, refreshUsers] = useUsers();
  const [userAddConfirmation, setUserAddConfirmation] = useState();
  const [addUser] = useImperativeNetworkRequest(addUserConfiguration);
  const [deactivate, isDeactivating] = useImperativeNetworkRequest(deactivatePartnerUsersConfiguration);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deactivateEffectiveDate, setDeactivateEffectiveDate] = useState();
  const [toastMessage, setToastMessage] = useState();

  const submitUser = useCallback(async ({ email, partnerId }) => {
    const result = await addUser({
      email,
      partnerId,
    });

    if (result.isSuccess()) {
      setUserAddConfirmation(`New User Added. Account Creation Link: ${result.data.accountCreationUrl}`);
      refreshUsers();
    } else {
      setUserAddConfirmation(`ERROR: Partner user ${email} could not be added.`);
    }
  }, [addUser, refreshUsers]);

  const activePartners = useMemo(() => {
    return partners.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [partners]);

  const handleRowSelect = useCallback((selectedRows) => {
    setSelectedUsers(
      selectedRows.map((row) => users.find((user) => user.id === row.userId))
    );
  }, [users]);

  const handleDeactivateCancel = () => {
    setDeactivateEffectiveDate(null);
  };

  const handleDeactivateConfirm = async () => {
    const result = await deactivate({
      userIds: selectedUsers.map((u) => u.id),
      deactivateAt: deactivateEffectiveDate,
    });

    setDeactivateEffectiveDate(null);

    if (result.isSuccess()) {
      setToastMessage(successToast({ message: 'User(s) deactivated' }));
      await refreshUsers();
    } else {
      setToastMessage(errorToast({ message: 'There was an error deactivating the user(s)' }));
    }
  };

  const isLoading = isLoadingUsers || networkCallsPending || isDeactivating;

  const renderTableHeader = () => {
    return (
      <div css={styles.tableHeader}>
        {selectedUsers.length > 0 && <PartnerUserDeactivate onApply={setDeactivateEffectiveDate} />}
        <div css={styles.buttonSection}>
          <div css={[styles.tableTopButton, styles.confirmationMessage]}>
            {userAddConfirmation}
          </div>
          <div css={styles.tableTopButton}>
            <AddUser
              activePartners={activePartners}
              isLoading={isLoadingUsers}
              onSubmit={submitUser}
            />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <DashboardLayout
        filters={
          <RuleFilters
            onChange={setSelectedFilters}
            partners={partners}
            partnerTypes={partnerTypes}
          />
        }
        table={
          <UsersTable
            onRowSelect={handleRowSelect}
            users={users}
            usersFilters={selectedFilters}
          />
        }
        tableHeader={renderTableHeader()}
      />
      {!!deactivateEffectiveDate &&
      <PartnerUserDeactivationConfirmationModal
        effectiveDate={deactivateEffectiveDate}
        onCancel={handleDeactivateCancel}
        onConfirm={handleDeactivateConfirm}
        users={selectedUsers}
      />
      }
      {toastMessage && (
        <Toast
          analyticsContext={'PARTNER_USERS'}
          toastMessage={toastMessage}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  confirmationMessage: {
    fontWeight: 'bold',
    color: Colors.success(),
    paddingTop: 8,
  },
  tableHeader: {
    padding: '12px 24px',
    display: 'flex',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
  },
  tableTopButton: {
    display: 'flex',
    marginRight: 8,
  },
});
