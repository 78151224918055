import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import TitledDropdown from '@root/internal-dashboards-core/src/components/titled-dropdown';
import useEscOrClickAwayToClose from '@root/internal-dashboards-core/src/hooks/use-esc-or-click-away-to-close';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { EventTypes } from '@root/partners-common/src/models/partner-payment-event';

export default function EventPricing({
  paymentEvent, handleEventChange, handleAmountChange, pricingType, handleRemoveEvent, showRemove,
}) {
  const showPercentIndicator = pricingType === 'commission';

  const {
    ref, isComponentVisible, setIsComponentVisible,
  } = useEscOrClickAwayToClose();
  const [eventTypeTitle, setEventTypeTitle] = useState();

  return (
    <div
      css={[styles.item, styles.eventPricing]}
      data-testid={`event-pricing-${paymentEvent.precedence + 1}`}
      key={paymentEvent.precedence}
    >
      <div
        css={[styles.item, {
          ...Responsive.lessThanXl({
            width: '100%',
          }),
        }]}
      >
        <div css={styles.selectLabel}>
          {`Event ${paymentEvent.precedence + 1}`}
          {showRemove && (
            <a
              css={styles.removeEventLink}
              onClick={handleRemoveEvent}
            >remove
            </a>
          )}
        </div>
        <div
          onClick={() => setIsComponentVisible(!isComponentVisible)}
          ref={ref}
        >
          <TitledDropdown
            buttonStyle={[styles.selectBoxStyles, !eventTypeTitle ? {
              color: Colors.gray60(),
            } : null]}
            chevronStyle={styles.chevron}
            closedChevronColor={Colors.gray40()}
            contents={EventTypes.OPTIONS}
            isOpen={isComponentVisible}
            onClick={(value, label) => {
              handleEventChange(value, paymentEvent.precedence);
              setEventTypeTitle(label);
            }}
            title={eventTypeTitle || 'Choose event'}
          />
        </div>
      </div>

      <div css={[styles.item, styles.amountInputContainer]}>
        <div
          css={styles.pricingIndicatorContainer}
        >
          <div
            css={[
              styles.pricingIndicator,
              showPercentIndicator ? styles.pricingTypeHighlight : null,
            ]}
          >{'%'}
          </div>
          <div
            css={[
              styles.pricingIndicator,
              !showPercentIndicator && pricingType ? styles.pricingTypeHighlight : null,
            ]}
          >{'$'}
          </div>
        </div>
        <input
          css={styles.amountInput}
          data-testid={`event-${paymentEvent.precedence + 1}-value`}
          onChange={(e) => handleAmountChange(e.target.value, paymentEvent.precedence)}
          placeholder={showPercentIndicator ? '-- %' : '$ --.--'}
          type={'number'}
          value={paymentEvent.paymentAmount || ''}
        />
      </div>
    </div>
  );
}

EventPricing.propTypes = {
  handleAmountChange: PropTypes.func,
  handleEventChange: PropTypes.func,
  handleRemoveEvent: PropTypes.func,
  paymentEvent: PropTypes.object,
  pricingType: PropTypes.string,
  showRemove: PropTypes.bool,
};

const styles = StyleSheet.create({
  item: {
    marginRight: 6,
    ...Responsive.lessThanXl({
      marginTop: 6,
      marginRight: 0,
    }),
  },
  chevron: {
    width: 18,
  },
  eventPricing: {
    display: 'flex',
  },
  removeEventLink: {
    color: Colors.rootOrange(),
    paddingLeft: 6,
    cursor: 'pointer',
  },
  amountInputContainer: {
    marginTop: 'auto',
    position: 'relative',
    ...Responsive.lessThanXl({
      marginTop: 'auto',
      marginLeft: 10,
    }),
  },
  amountInput: {
    borderRadius: 4,
    border: `1px solid ${Colors.gray40()}`,
    width: 120,
    height: 34,
    paddingLeft: 55,
    ...Responsive.lessThanXl({
      width: '100%',
    }),
    '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
  pricingIndicatorContainer: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  pricingIndicator: {
    marginLeft: 8,
    color: Colors.gray30(),
  },
  pricingTypeHighlight: {
    backgroundColor: '#E8EBF1',
    borderRadius: 3,
    color: Colors.gray50(),
    width: 20,
    textAlign: 'center',
    padding: 1,
  },
  selectLabel: {
    color: Colors.gray50(),
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 3,
  },
  selectBoxStyles: {
    height: 34,
    width: 130,
    borderRadius: 4,
    fontSize: 14,
    margin: 'auto',
    backgroundColor: Colors.white(),
    color: Colors.black(),
    border: `1px solid ${Colors.gray20()}`,
    ...Responsive.lessThanXl({
      width: '100%',
    }),
  },
});
