import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const HeaderLayout = ({
  title, styling, children,
}) => (
  <div
    css={[styles.topSection, styling]}
    data-testid={'header'}
  >
    <div>{title}</div>
    <div>{children}</div>
  </div>
);

HeaderLayout.propTypes = {
  children: PropTypes.node,
  styling: PropTypes.object,
  title: PropTypes.string,
};

export default HeaderLayout;

const styles = StyleSheet.create({
  topSection: {
    background: Colors.white(),
    padding: 10,
    paddingLeft: 21,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: 50,
    fontWeight: 'bold',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
});
