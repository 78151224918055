import Market from '@root/core/src/models/market';
import ProductTypes from '@root/partners.admin.joinroot.com/src/models/product-types';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useMemo, useState } from '@root/vendor/react';
import Table, { FILTER_TYPES } from '@root/internal-dashboards-core/src/components/table';
import infoIcon from '@root/partners.admin.joinroot.com/src/assets/icons/info-icon.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function TriageRulesTable({
  onTriageRuleSelection, activeTriageRuleId, triageRuleFilters, onRowSelect = () => {}, triageRules,
}) {
  const [filters, setFilters] = useState([]);

  const data = useMemo(() => {
    return triageRules.map((triageRule) => {
      return {
        productType: ProductTypes.Titles[triageRule.productType.toUpperCase()],
        market: Market.MAPPING[triageRule.market],
        marketId: triageRule.market,
        partnerId: triageRule.partner.id,
        partnerName: triageRule.partner.name,
        partnerType: triageRule.partner.partnerType.name,
        partnerTypeId: triageRule.partner.partnerType.id,
        id: triageRule.id,
        active: activeTriageRuleId === triageRule.id,
      };
    });
  }, [activeTriageRuleId, triageRules]);

  useEffect(() => {
    setFilters(triageRuleFilters
      .filter((x) => x.value && x.value.length > 0)
      .map((filter) => {
        return {
          ...filter,
          type: FILTER_TYPES.INCLUDES_SOME,
        };
      }));
  }, [triageRuleFilters]);

  const onRowClick = (row) => {
    const triageRule = triageRules.find((rule) => rule.id === row.id);
    onTriageRuleSelection(triageRule);
  };

  const columns = useMemo(() => {
    return [{
      Header: 'Partner Type',
      accessor: 'partnerType',
    }, {
      Header: 'Partner Type Id',
      accessor: 'partnerTypeId',
    }, {
      Header: 'Partner Id',
      accessor: 'partnerId',
    }, {
      Header: 'Partner',
      accessor: 'partnerName',
    }, {
      Header: 'Market Id',
      accessor: 'marketId',
    }, {
      Header: 'Market',
      accessor: 'market',
    }, {
      Header: 'Product',
      accessor: 'productType',
    }];
  }, []);

  return (
    <div css={styles.container}>
      <Table
        clickable={true}
        columns={columns}
        data={data}
        filters={filters}
        initialState={{
          hiddenColumns: [
            'partnerId',
            'partnerTypeId',
            'marketId',
          ],
        }}
        onRowClick={onRowClick}
        onRowSelect={onRowSelect}
        searchable={false}
        selectable={true}
        useVirtualRows={true}
      />
      {triageRules.length === 0 && (
        <div css={styles.noTriageRule}>
          <img
            alt={'info icon'}
            css={styles.infoIcon}
            src={infoIcon}
          />
          0 results found based on your filter criteria
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
  },
  noTriageRule: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 36,
    backgroundColor: Colors.white(),
  },
  infoIcon: {
    paddingRight: 15,
    height: 25,
  },
});

TriageRulesTable.propTypes = {
  activeTriageRuleId: PropTypes.string,
  onRowSelect: PropTypes.func.isRequired,
  onTriageRuleSelection: PropTypes.func.isRequired,
  triageRuleFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
  })),
  triageRules: PropTypes.array,
};
