import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function getPartnerEarningsConfiguration({ partnerIds = [], earnedMonths = []} = {}) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'earnings/partner_earnings',
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      partnerIds,
      earnedMonths,
    },
  });
}
