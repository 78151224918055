export default () => (
  <svg
    fill={'none'}
    height={'24'}
    viewBox={'0 0 24 24'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <circle
      cx={'10.5'}
      cy={'10'}
      r={'7.75'}
      stroke={'#606060'}
      strokeWidth={'1.5'}
    />
    <path
      d={'M16 16L22 22'}
      stroke={'#606060'}
      strokeWidth={'1.5'}
    />
  </svg>
);
