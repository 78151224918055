import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export function createPartnerConfiguration(body) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'partners',
    bodyParameters: body,
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.POST,
    errorCodes: [400, 500],
  });
}

export function updatePartnerConfiguration(body) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'partners/' + body.id,
    bodyParameters: body,
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.PUT,
    errorCodes: [400, 500],
  });
}
