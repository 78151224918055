import React from '@root/vendor/react';

export default function PartnerProgramLogo() {
  return (
    <svg
      aria-label={'Root Insurance Co Partners logo'}
      data-testid={'root-partners-logo'}
      fill={'none'}
      height={'18'}
      viewBox={'0 0 206 18'}
      width={'206'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M9.29364 9.08173L9.56323 8.94486C11.2758 8.07313 12.2581 6.53985 12.2581 4.7388C12.2581 3.37606 11.8258 2.30336 10.9735 1.55112C9.93916 0.637559 8.27166 0.174805 6.01718 0.174805C4.15043 0.174805 1.79391 0.495256 0.842285 0.629955V14.323H4.56449V9.67104H6.15197L8.78614 14.323H12.9127L9.29364 9.08173ZM5.93071 7.40126H4.56449V2.92689L4.70734 2.90245C5.01936 2.84868 5.4839 2.81229 5.86358 2.81229C7.48168 2.81229 8.37316 3.62645 8.37316 5.10433C8.37369 6.41384 7.32325 7.40126 5.93071 7.40126Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M24.2317 3.61268C23.0615 2.5465 21.4348 1.95937 19.6508 1.95937C17.8904 1.95937 16.2787 2.5465 15.1134 3.61214C13.8411 4.77554 13.1687 6.43212 13.1687 8.40208C13.1687 10.3715 13.8411 12.0259 15.1134 13.1866C16.2777 14.249 17.8893 14.8345 19.6508 14.8345C21.4359 14.8345 23.0626 14.249 24.2317 13.1866C25.505 12.0286 26.1785 10.3742 26.1785 8.40208C26.1785 6.4294 25.505 4.77337 24.2317 3.61268ZM21.3371 11.2058C20.9305 11.7717 20.3575 12.071 19.6803 12.071C19.0053 12.071 18.4339 11.7717 18.0279 11.2058C17.5719 10.5703 17.341 9.62686 17.341 8.40208C17.341 7.17622 17.5719 6.2317 18.0273 5.59351C18.4339 5.0243 19.0053 4.7234 19.6798 4.7234C20.3564 4.7234 20.9295 5.0243 21.3371 5.59351C21.793 6.23115 22.0245 7.17622 22.0245 8.40208C22.0245 9.6274 21.7936 10.5708 21.3371 11.2058Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M45.7975 9.64165V5.79622H48.4908V3.00666H45.7975V0.270872L41.9357 1.45274L41.9545 10.7643C41.9545 11.9223 42.3009 12.9113 42.9571 13.6239C43.6263 14.3512 44.5763 14.7357 45.7035 14.7357C47.0461 14.7357 48.4263 14.5163 49.1277 14.2969V11.0104C48.7067 11.1331 48.11 11.2526 47.3796 11.2526C46.0666 11.2531 45.7975 10.609 45.7975 9.64165Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M38.624 3.61268C37.4538 2.5465 35.8271 1.95937 34.0431 1.95937C32.2827 1.95937 30.671 2.5465 29.5057 3.61214C28.2334 4.77554 27.561 6.43212 27.561 8.40208C27.561 10.3715 28.2334 12.0259 29.5057 13.1866C30.67 14.249 32.2816 14.8345 34.0431 14.8345C35.8282 14.8345 37.4549 14.249 38.624 13.1866C39.8973 12.0286 40.5708 10.3742 40.5708 8.40208C40.5708 6.4294 39.8979 4.77337 38.624 3.61268ZM35.7299 11.2058C35.3234 11.7717 34.7504 12.071 34.0732 12.071C33.3981 12.071 32.8267 11.7717 32.4207 11.2058C31.9648 10.5703 31.7338 9.62686 31.7338 8.40208C31.7338 7.17622 31.9648 6.2317 32.4202 5.59351C32.8267 5.0243 33.3981 4.7234 34.0726 4.7234C34.7493 4.7234 35.3223 5.0243 35.7299 5.59351C36.1859 6.23115 36.4173 7.17622 36.4173 8.40208C36.4173 9.6274 36.1859 10.5708 35.7299 11.2058Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M56.9269 4.375C58.2386 4.375 59.2027 4.6889 59.8193 5.3167C60.4471 5.94451 60.761 6.77971 60.761 7.82231C60.761 8.97702 60.4022 9.91872 59.6847 10.6474C58.9785 11.3649 57.9471 11.7237 56.5906 11.7237H55.8002V14.8346H54.4381V4.62724C54.965 4.54877 55.4134 4.48711 55.7834 4.44226C56.1646 4.39742 56.5457 4.375 56.9269 4.375ZM56.8092 10.5129C57.6724 10.5129 58.3058 10.2606 58.7094 9.75617C59.1242 9.25168 59.3316 8.63509 59.3316 7.90639C59.3316 7.13285 59.1186 6.54989 58.6926 6.15751C58.2666 5.75392 57.6836 5.55213 56.9437 5.55213C56.5401 5.55213 56.159 5.59697 55.8002 5.68666V10.5129H56.8092Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M65.0335 7.06558C65.7622 7.06558 66.3395 7.23374 66.7655 7.57007C67.1915 7.89518 67.4045 8.41648 67.4045 9.13397V14.8346H66.1265L66.0761 13.9266C65.4707 14.6665 64.7252 15.0364 63.8395 15.0364C63.2341 15.0364 62.7296 14.8683 62.3261 14.5319C61.9225 14.1956 61.7207 13.708 61.7207 13.0689C61.7207 11.6003 62.7689 10.7707 64.8653 10.5802L66.0592 10.4793V9.47029C66.0592 8.67433 65.5996 8.27634 64.6803 8.27634C64.1983 8.27634 63.7947 8.3324 63.4696 8.44451C63.1444 8.5454 62.7969 8.68554 62.427 8.86491L62.1075 7.85594C62.455 7.63173 62.8978 7.44675 63.4359 7.30101C63.974 7.14406 64.5066 7.06558 65.0335 7.06558ZM64.1758 13.9266C64.9045 13.9266 65.5323 13.5846 66.0592 12.9008V11.4546L64.529 11.6396C64.0805 11.6956 63.7218 11.8302 63.4527 12.0432C63.1949 12.245 63.066 12.5364 63.066 12.9176C63.066 13.2315 63.1613 13.4781 63.3518 13.6575C63.5424 13.8369 63.8171 13.9266 64.1758 13.9266Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M71.0698 9.08352C71.2828 8.48935 71.6415 8.00729 72.146 7.63733C72.6505 7.25617 73.183 7.06558 73.7436 7.06558V8.42769C72.4543 8.70796 71.5631 9.28531 71.0698 10.1598V14.8346H69.7245V7.26738H71.0698V9.08352Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M79.826 14.6328C79.187 14.9019 78.52 15.0364 77.8249 15.0364C77.1523 15.0364 76.6422 14.8851 76.2946 14.5824C75.9471 14.2685 75.7733 13.7696 75.7733 13.0858V8.37724H74.3103V7.26738H75.7733V5.36715L77.1186 5.08128V7.26738H79.5233V8.37724H77.1186V12.8503C77.1186 13.2091 77.1971 13.4557 77.354 13.5902C77.5222 13.7248 77.7969 13.792 78.178 13.792C78.7386 13.792 79.2094 13.708 79.5906 13.5398L79.826 14.6328Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M85.6052 7.06558C86.1882 7.06558 86.6703 7.23935 87.0514 7.58688C87.4326 7.93442 87.6232 8.46693 87.6232 9.18442V14.8346H86.2779V9.60482C86.2779 8.71917 85.8967 8.27634 85.1344 8.27634C84.7308 8.27634 84.3104 8.39966 83.8732 8.6463C83.4472 8.89294 83.0716 9.18442 82.7465 9.52074V14.8346H81.4012V7.26738H82.7465V8.39406C83.1277 8.01289 83.5705 7.69899 84.075 7.45235C84.5907 7.19451 85.1008 7.06558 85.6052 7.06558Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M96.1375 11.0678H90.8909C90.9021 11.9759 91.1431 12.6654 91.614 13.1362C92.0849 13.5958 92.6958 13.8257 93.447 13.8257C94.2093 13.8257 94.8988 13.5958 95.5153 13.1362L95.9357 14.1115C95.1622 14.7281 94.2541 15.0364 93.2115 15.0364C92.0792 15.0364 91.1712 14.6833 90.4873 13.977C89.8147 13.2707 89.4783 12.3066 89.4783 11.0846C89.4783 10.2775 89.6353 9.57119 89.9492 8.96581C90.2631 8.34921 90.6891 7.87836 91.2272 7.55325C91.7766 7.22814 92.3819 7.06558 93.0434 7.06558C93.6824 7.06558 94.2373 7.21132 94.7082 7.5028C95.179 7.78307 95.5322 8.16424 95.7676 8.6463C96.0142 9.12836 96.1375 9.64966 96.1375 10.2102V11.0678ZM93.0097 8.15863C92.5165 8.15863 92.0905 8.32119 91.7317 8.6463C91.373 8.9602 91.1263 9.42545 90.9918 10.042H94.8091C94.6521 8.78643 94.0523 8.15863 93.0097 8.15863Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M99.1692 9.08352C99.3822 8.48935 99.7409 8.00729 100.245 7.63733C100.75 7.25617 101.282 7.06558 101.843 7.06558V8.42769C100.554 8.70796 99.6624 9.28531 99.1692 10.1598V14.8346H97.8239V7.26738H99.1692V9.08352Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M108.544 4.375C109.856 4.375 110.82 4.6889 111.436 5.3167C112.064 5.94451 112.378 6.77971 112.378 7.82231C112.378 8.97702 112.019 9.91872 111.302 10.6474C110.595 11.3649 109.564 11.7237 108.208 11.7237H107.417V14.8346H106.055V4.62724C106.582 4.54877 107.03 4.48711 107.4 4.44226C107.782 4.39742 108.163 4.375 108.544 4.375ZM108.426 10.5129C109.289 10.5129 109.923 10.2606 110.326 9.75617C110.741 9.25168 110.949 8.63509 110.949 7.90639C110.949 7.13285 110.736 6.54989 110.31 6.15751C109.884 5.75392 109.301 5.55213 108.561 5.55213C108.157 5.55213 107.776 5.59697 107.417 5.68666V10.5129H108.426Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M115.37 9.08352C115.583 8.48935 115.942 8.00729 116.447 7.63733C116.951 7.25617 117.484 7.06558 118.044 7.06558V8.42769C116.755 8.70796 115.864 9.28531 115.37 10.1598V14.8346H114.025V7.26738H115.37V9.08352Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M122.499 15.0364C121.86 15.0364 121.261 14.8795 120.7 14.5656C120.151 14.2517 119.708 13.7976 119.372 13.2035C119.035 12.5981 118.867 11.8806 118.867 11.051C118.867 10.2214 119.035 9.50953 119.372 8.91536C119.708 8.30998 120.151 7.85034 120.7 7.53643C121.261 7.22253 121.86 7.06558 122.499 7.06558C123.138 7.06558 123.738 7.22253 124.299 7.53643C124.859 7.85034 125.308 8.30998 125.644 8.91536C125.98 9.50953 126.149 10.2214 126.149 11.051C126.149 11.8806 125.98 12.5981 125.644 13.2035C125.308 13.7976 124.859 14.2517 124.299 14.5656C123.738 14.8795 123.138 15.0364 122.499 15.0364ZM122.499 13.9434C123.127 13.9434 123.654 13.6967 124.08 13.2035C124.517 12.7102 124.736 11.9927 124.736 11.051C124.736 10.1093 124.517 9.39182 124.08 8.89854C123.654 8.40527 123.127 8.15863 122.499 8.15863C121.883 8.15863 121.356 8.40527 120.919 8.89854C120.493 9.39182 120.28 10.1093 120.28 11.051C120.28 11.9927 120.493 12.7102 120.919 13.2035C121.356 13.6967 121.883 13.9434 122.499 13.9434Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M134.24 7.26738V14.1115C134.24 15.199 133.915 16.051 133.264 16.6676C132.614 17.2954 131.751 17.6093 130.675 17.6093C130.159 17.6093 129.671 17.5364 129.212 17.3907C128.752 17.2562 128.377 17.0544 128.085 16.7853L128.472 15.7763C128.696 15.9557 129.004 16.1183 129.397 16.264C129.789 16.4098 130.215 16.4826 130.675 16.4826C131.258 16.4826 131.773 16.3313 132.222 16.0286C132.67 15.7259 132.894 15.171 132.894 14.3638V13.6071C132.323 14.3358 131.544 14.7001 130.557 14.7001C129.671 14.7001 128.943 14.3918 128.371 13.7752C127.81 13.1586 127.53 12.2618 127.53 11.0846C127.53 10.2326 127.693 9.50392 128.018 8.89854C128.343 8.29316 128.78 7.83913 129.329 7.53643C129.879 7.22253 130.495 7.06558 131.179 7.06558C131.986 7.06558 132.67 7.28419 133.231 7.72141L133.988 7.26738H134.24ZM131.028 13.5566C131.398 13.5566 131.734 13.4837 132.037 13.338C132.34 13.1923 132.625 12.968 132.894 12.6654V8.6463C132.468 8.36603 131.981 8.2259 131.431 8.2259C130.692 8.2259 130.081 8.46693 129.599 8.94899C129.128 9.43105 128.892 10.1373 128.892 11.0678C128.892 11.9086 129.088 12.5364 129.481 12.9512C129.884 13.3548 130.4 13.5566 131.028 13.5566Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M137.911 9.08352C138.124 8.48935 138.482 8.00729 138.987 7.63733C139.491 7.25617 140.024 7.06558 140.584 7.06558V8.42769C139.295 8.70796 138.404 9.28531 137.911 10.1598V14.8346H136.565V7.26738H137.911V9.08352Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M144.75 7.06558C145.479 7.06558 146.056 7.23374 146.482 7.57007C146.908 7.89518 147.121 8.41648 147.121 9.13397V14.8346H145.843L145.792 13.9266C145.187 14.6665 144.442 15.0364 143.556 15.0364C142.951 15.0364 142.446 14.8683 142.042 14.5319C141.639 14.1956 141.437 13.708 141.437 13.0689C141.437 11.6003 142.485 10.7707 144.582 10.5802L145.776 10.4793V9.47029C145.776 8.67433 145.316 8.27634 144.397 8.27634C143.915 8.27634 143.511 8.3324 143.186 8.44451C142.861 8.5454 142.513 8.68554 142.143 8.86491L141.824 7.85594C142.171 7.63173 142.614 7.44675 143.152 7.30101C143.69 7.14406 144.223 7.06558 144.75 7.06558ZM143.892 13.9266C144.621 13.9266 145.249 13.5846 145.776 12.9008V11.4546L144.245 11.6396C143.797 11.6956 143.438 11.8302 143.169 12.0432C142.911 12.245 142.782 12.5364 142.782 12.9176C142.782 13.2315 142.878 13.4781 143.068 13.6575C143.259 13.8369 143.533 13.9266 143.892 13.9266Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M158.236 7.06558C158.819 7.06558 159.301 7.23935 159.682 7.58688C160.074 7.93442 160.27 8.46693 160.27 9.18442V14.8346H158.908V9.60482C158.908 8.71917 158.527 8.27634 157.765 8.27634C157.406 8.27634 157.019 8.37164 156.605 8.56222C156.201 8.7528 155.842 8.98823 155.528 9.2685V14.8346H154.166V9.60482C154.166 8.71917 153.785 8.27634 153.023 8.27634C152.664 8.27634 152.277 8.37164 151.862 8.56222C151.459 8.7528 151.1 8.98823 150.786 9.2685V14.8346H149.441V7.26738H150.786V8.32679C151.167 7.95684 151.599 7.65415 152.081 7.41872C152.563 7.1833 153.034 7.06558 153.494 7.06558C153.953 7.06558 154.357 7.17769 154.704 7.40191C155.063 7.61491 155.304 7.95123 155.427 8.41087C155.809 8.02971 156.251 7.7102 156.756 7.45235C157.26 7.19451 157.754 7.06558 158.236 7.06558Z'}
        fill={'#8394B4'}
      />
      <path
        d={'M170.345 12.5545H167.33L166.64 14.8345H164.93L167.93 5.68447H169.865L172.865 14.8345H171.05L170.345 12.5545ZM169.925 11.2495L168.815 7.69447L167.735 11.2495H169.925ZM176.903 5.68447C178.483 5.68447 179.658 6.04947 180.428 6.77947C181.198 7.49947 181.583 8.61447 181.583 10.1245C181.583 13.2645 180.023 14.8345 176.903 14.8345H174.338V5.68447H176.903ZM177.083 13.4995C177.893 13.4995 178.523 13.2295 178.973 12.6895C179.423 12.1395 179.648 11.2995 179.648 10.1695C179.648 9.06947 179.423 8.27447 178.973 7.78447C178.533 7.29447 177.903 7.04947 177.083 7.04947H176.153V13.4995H177.083ZM191.042 14.8345L190.652 8.27947L188.402 13.5145H187.412L185.162 8.27947L184.772 14.8345H183.122L183.677 5.68447H185.522L187.937 11.0095L190.352 5.68447H192.122L192.677 14.8345H191.042ZM194.685 5.68447H196.515V14.8345H194.685V5.68447ZM205.792 5.68447V14.8345H204.007L200.362 8.56447V14.8345H198.772V5.68447H200.557L204.232 11.9695V5.68447H205.792Z'}
        fill={'#FF5715'}
      />
    </svg>

  );
}
