import CancelPendingRuleConfirmationModal from '@root/partners.admin.joinroot.com/src/components/cancel-pending-rule-confirmation-modal';
import DashboardLayout from '@root/partners.admin.joinroot.com/src/components/dashboard-layout';
import HeaderLayout from '@root/partners.admin.joinroot.com/src/components/header-layout';
import Loading from '@root/partners.admin.joinroot.com/src/components/loading';
import PartnerAdminWrapper from '@root/partners.admin.joinroot.com/src/components/partners-admin-wrapper';
import PartnersAndMarketsContext from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context';
import PricingRulesTable from '@root/partners.admin.joinroot.com/src/components/pricing-rules-table';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import React, { useCallback, useContext, useState } from '@root/vendor/react';
import RuleFilters from '@root/partners.admin.joinroot.com/src/components/rule-filters';
import Toast, { TOAST_TYPES } from '@root/partners.admin.joinroot.com/src/components/toast';
import cancelPendingRulesConfiguration from '@root/partners.admin.joinroot.com/src/api/cancel-pending-rules/cancel-pending-rules-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import usePendingPricingRules from '@root/partners.admin.joinroot.com/src/hooks/use-pending-pricing-rules';
import useShowModal from '@root/core/src/hooks/use-show-modal';
import { RootError } from '@root-common/root-errors';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function PricingPendingRules() {
  const {
    supportedMarkets, partners, partnerTypes, networkCallsPending,
  } = useContext(PartnersAndMarketsContext);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedPricingRules, setSelectedPricingRules] = useState([]);
  const [pendingPricingRules, isLoadingPendingPricingRules, refreshPendingRules] = usePendingPricingRules();
  const [showConfirmationModal, show, hide] = useShowModal();
  const [toastMessage, setToastMessage] = useState(null);
  const [cancelPendingRules, isCanceling] = useImperativeNetworkRequest(cancelPendingRulesConfiguration);

  const handleCancelRule = async () => {
    hide();

    const result = await cancelPendingRules(selectedPricingRules.map((row) => row.id));

    if (result.isSuccess()) {
      await refreshPendingRules();
    }

    setToastMessage({
      message: result.isSuccess() ? 'Pending rules canceled successfully' : 'There was an error canceling pending rules',
      title: result.isSuccess() ? 'Success' : 'Error',
      type: result.isSuccess() ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
    });
  };

  const handleRowSelect = useCallback((selectedRows) => {
    const rules = selectedRows.map((row) => {
      const matchingRule = pendingPricingRules.find((rule) => rule.market === row.marketId && rule.partner.id === row.partnerId);
      if (!matchingRule) {
        throw new RootError({
          message: `Could not find pricing rule with market ${row.marketId} and partner ${row.partnerId}`,
          name: 'PricingPendingRulesError',
          fingerprint: ['PricingPendingRulesNotFoundPricingRule'],
        });
      }

      return matchingRule;
    });

    setSelectedPricingRules(rules);
  }, [pendingPricingRules]);

  const displayLoader = isLoadingPendingPricingRules || networkCallsPending || isCanceling;

  return (
    <PartnerAdminWrapper>
      <HeaderLayout title={'Pending Pricing Rules'} >
        {
          selectedPricingRules.length > 0 &&
          <div css={styles.tableTopButton}>
            <PrimaryButton
              css={styles.button}
              onClick={show}
            >{'Cancel Pending Rule'}
            </PrimaryButton>
          </div>
        }
      </HeaderLayout>
      {
        !displayLoader && (
          <DashboardLayout
            filters={
              <RuleFilters
                markets={supportedMarkets}
                onChange={setSelectedFilters}
                partners={partners}
                partnerTypes={partnerTypes}
              />
            }
            table={
              <PricingRulesTable
                onRowSelect={handleRowSelect}
                pricingRuleFilters={selectedFilters}
                pricingRules={pendingPricingRules}
                showEffective={true}
              />
            }
          />)
      }

      {displayLoader && <Loading />}

      <Toast
        analyticsContext={'PENDING_PRICING_RULES'}
        toastMessage={toastMessage}
      />
      <CancelPendingRuleConfirmationModal
        onCancel={hide}
        onConfirm={handleCancelRule}
        pricingRules={selectedPricingRules}
        shouldShow={showConfirmationModal}
      />
    </PartnerAdminWrapper>
  );
}

const styles = StyleSheet.create({
  button: {
    position: 'relative',
    width: 175,
    padding: 7,
  },
  tableTopButton: {
    display: 'inline-block',
    marginRight: 8,
  },
});
