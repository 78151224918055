import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function getPricingRulesConfiguration({
  markets = [], partnerIds = [], partnerTypeIds = [], includeExpired,
} = {}) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'pricing_rules',
    queryParameters: {
      markets,
      partnerIds,
      partnerTypeIds,
      includeExpired,
    },
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.GET,
  });
}
