import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function PartnerEarningsDialogTable({
  earningAdjustmentAmount, partnerEarnings = [],
}) {
  return (
    <div css={styles.tableContainer}>
      <table css={styles.table}>
        <thead css={styles.tableHead}>
          <tr>
            <th width={'130px'}>Partner</th>
            <th width={'90px'}>Earned Month</th>
            <th width={'90px'}>Finalized</th>
            <th width={'115px'}>Earning Adjustment</th>
            <th width={'115px'}>Total Earning</th>
          </tr>
        </thead>
        <tbody>
          {partnerEarnings.map((earning, i) => (
            <React.Fragment key={i}>
              <tr
                css={i % 2 === 0 ? styles.evenRow : styles.oddRow}
                key={`expiringRow${i}`}
              >
                <td css={styles.mergedCell}><span>{earning.partnerName}</span></td>
                <td css={styles.mergedCell}><span>{earning.earnedMonth}</span></td>
                <td css={[styles.mergedCell, styles.finalMergedCell]}><span>{String(earning.finalized)}</span></td>

                {earning.earningAdjustment ?
                  <td><span css={styles.expiredBadge}>EXPIRING</span>{earning.earningAdjustment}</td>
                  :
                  <td><span css={styles.emptyBadge} />{'--'}</td>
                }
                <td>{earning.totalEarning}</td>
              </tr>
              <tr
                css={i % 2 === 0 ? styles.evenRow : styles.oddRow}
                key={`newRow${i}`}
              >
                <td />
                <td />
                <td css={styles.finalMergedCell} />

                <td><span css={styles.newBadge}>NEW</span>{earningAdjustmentAmount}</td>
                <td>{earning.totalEarning - earning.earningAdjustment + earningAdjustmentAmount}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const styles = StyleSheet.create({
  mergedCell: {
    'tr td&': {
      textAlign: 'left',
      borderBottom: '0px none',
      paddingLeft: 11,
      span: {
        position: 'relative',
        top: 20,
      },
    },
  },
  finalMergedCell: {
    'tr td&': {
      textAlign: 'left',
      borderRight: `1px solid ${Colors.gray30()}`,
      padding: 0,
      paddingLeft: 11,
    },
  },
  table: {
    borderCollapse: 'collapse',
    color: Colors.gray50(),
    fontSize: 13.5,
    width: 965,
    th: {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 11,
      border: `1px solid ${Colors.gray30()}`,
      borderLeft: '0px none',
      textAlign: 'left',
      fontWeight: 'normal',
      '&:first-of-type': {
        borderLeft: `1px solid ${Colors.gray30()}`,
      },
    },
    td: {
      paddingLeft: 11,
      borderBottom: `1px solid ${Colors.gray30()}`,
      paddingTop: 8,
      paddingBottom: 8,
      '&:first-of-type': {
        borderLeft: `1px solid ${Colors.gray30()}`,
      },
      '&:last-child': {
        borderRight: `1px solid ${Colors.gray30()}`,
      },
    },
  },
  tableHead: {
    backgroundColor: Colors.nearWhite(),
  },
  expiredBadge: {
    color: Colors.gray50(),
    fontSize: 9.5,
    marginRight: 27,
    fontWeight: 'bold',
  },
  newBadge: {
    backgroundColor: Colors.lightGreen(),
    color: 'white',
    padding: '3px 9px',
    fontSize: 9.5,
    borderRadius: 2,
    marginRight: 28,
    fontWeight: 'bold',
  },
  emptyBadge: {
    marginLeft: 64,
  },
  evenRow: {
    background: Colors.nearWhite(),
  },
  oddRow: {
    background: Colors.white(),
  },
  tableContainer: {
    overflow: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: '5px 0',
  },
});

PartnerEarningsDialogTable.propTypes = {
  earningAdjustmentAmount: PropTypes.number.isRequired,
  partnerEarnings: PropTypes.arrayOf(PropTypes.shape({
    accountCount: PropTypes.number,
    attributedEarnedEventCount: PropTypes.number,
    costPerProfile: PropTypes.number,
    earnedMonth: PropTypes.string.isRequired,
    earning: PropTypes.number.isRequired,
    earningAdjustment: PropTypes.number.isRequired,
    finalized: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    installCount: PropTypes.number,
    partnerId: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
    partnerType: PropTypes.string,
    partnerTypeId: PropTypes.string,
    policyCount: PropTypes.number,
    profileCount: PropTypes.number,
    profileToBindRatio: PropTypes.number,
    quoteViewedCount: PropTypes.number,
    rootQualityIndicator: PropTypes.number,
    testDriveCount: PropTypes.number,
    tier: PropTypes.number,
    totalEarning: PropTypes.number.isRequired,
  })).isRequired,
};
