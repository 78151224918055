import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function PrimaryButton({
  onClick, children, className, disabled,
}) {
  return (
    <button
      className={className}
      css={styles.button}
      disabled={disabled}
      onClick={onClick}
    >{children}
    </button>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  button: {
    height: 34,
    borderRadius: 2,
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
    border: 'none',
    fontWeight: 600,
    color: Colors.white(),
    backgroundColor: Colors.rootOrange(),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    ':focus': {
      outline: 'none',
    },
    '&[disabled]': {
      backgroundColor: Colors.gray30(),
      cursor: 'not-allowed',
    },
  },
});
