import ProductTypes from '@root/partners.admin.joinroot.com/src/models/product-types';
import PropTypes from '@root/vendor/prop-types';
import TitledDropdown from '@root/internal-dashboards-core/src/components/titled-dropdown';
import useEscOrClickAwayToClose from '@root/internal-dashboards-core/src/hooks/use-esc-or-click-away-to-close';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function AssignProduct({ onClick }) {
  const {
    ref, isComponentVisible, setIsComponentVisible,
  } = useEscOrClickAwayToClose();

  const contents = Object.keys(ProductTypes.Types).map((product_type) => {
    return {
      value: ProductTypes.Types[product_type],
      label: ProductTypes.Titles[product_type],
    };
  });

  return (
    <div
      onClick={() => setIsComponentVisible(!isComponentVisible)}
      ref={ref}
    >
      <TitledDropdown
        buttonStyle={styles.button}
        chevronStyle={styles.chevronStyle}
        closedButtonStyle={styles.closedAssignButton}
        contents={contents}
        isOpen={isComponentVisible}
        onClick={onClick}
        openButtonStyle={styles.openSelection}
        title={'Assign Product'}
      />
    </div>
  );
}

AssignProduct.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  button: {
    fontSize: 14,
    width: 175,
    padding: 7,
    zIndex: 0,
  },
  closedAssignButton: {
    color: Colors.white(),
    border: `1px solid ${Colors.rootOrange()}`,
    backgroundColor: Colors.rootOrange(),
  },
  openSelection: {
    color: Colors.gray50(),
    border: `1px solid ${Colors.white()}`,
    backgroundColor: Colors.white(),
  },
  chevronStyle: {
    width: 16,
    height: 16,
  },
});
