export default class PartnerTypes {
  static Types = [
    {
      label: 'Affiliate marketer',
      value: 'affiliate_marketer',
    },
    {
      label: 'Affiliate network',
      value: 'affiliate_network',
    },
    {
      label: 'Aggregator',
      value: 'aggregator',
    },
    {
      label: 'Broker',
      value: 'broker',
    },
    {
      label: 'Call Center',
      value: 'call_center',
    },
    {
      label: 'Dealership',
      value: 'dealership',
    },
    {
      label: 'Finance',
      value: 'finance',
    },
    {
      label: 'Fintech',
      value: 'fintech',
    },
    {
      label: 'Independent agent',
      value: 'independent_agent',
    },
    {
      label: 'Influencer',
      value: 'influencer',
    },
    {
      label: 'Insuretech',
      value: 'insuretech',
    },
    {
      label: 'In App',
      value: 'in_app',
    },
    {
      label: 'Media Buyer',
      value: 'media_buyer',
    },
    {
      label: 'Other',
      value: 'other',
    },
    {
      label: 'Programmatic',
      value: 'programmatic',
    },
    {
      label: 'Publisher',
      value: 'publisher',
    },
    {
      label: 'Software',
      value: 'software',
    },
    {
      label: 'Vertical search',
      value: 'vertical_search',
    },
  ];
}
