import PartnerAdminDropdown from '@root/partners.admin.joinroot.com/src/components/partner-admin-dropdown';
import PartnerProgramLogo from '@root/partners.admin.joinroot.com/src/components/partner-program-logo';
import PropTypes from '@root/vendor/prop-types';
import ZIndex from '@root/core/src/utils/z-index';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useEscOrClickAwayToClose from '@root/internal-dashboards-core/src/hooks/use-esc-or-click-away-to-close';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { Routes } from '@root/partners.admin.joinroot.com/src/app-entry';
import { useHistory, useLocation } from '@root/vendor/react-router-dom';
import { useOktaAuth } from '@root/vendor/@okta/okta-react';

export default function PartnerProgramHeader() {
  const location = useLocation();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { trackClick } = useAnalytics('NAVIGATION');
  const pricingDropdown = useEscOrClickAwayToClose();
  const partnersDropdown = useEscOrClickAwayToClose();

  const handleLogout = () => {
    trackClick('LOGOUT');
    oktaAuth.signOut({ postLogoutRedirectUri: location.origin + '/' });
  };

  const handleHomePress = () => {
    trackClick('HOME');
    history.push(Routes.HOME);
  };

  const handleTriagePress = () => {
    trackClick('TRIAGE');
    history.push(Routes.TRIAGE);
  };

  return (
    <div css={styles.header}>
      <div
        css={styles.container}
      >
        <div
          css={styles.logo}
        >
          <PartnerProgramLogo />
        </div>
        <div
          css={styles.rightSideNav}
        >
          <div css={styles.links}>
            <div
              css={location.pathname === Routes.HOME ? styles.activeLink : styles.inactiveLink}
              onClick={handleHomePress}
            >
              HOME
            </div>
            <div
              css={location.pathname === Routes.TRIAGE ? styles.activeLink : styles.inactiveLink}
              onClick={handleTriagePress}
            >
              TRIAGE
            </div>
            <div
              css={styles.inactiveLink}
              onClick={() => pricingDropdown.setIsComponentVisible(!pricingDropdown.isComponentVisible)}
              ref={pricingDropdown.ref}
            >
              <PartnerAdminDropdown
                baseRoute={Routes.PRICING}
                dropdownTitle={'PRICING'}
                isOpen={pricingDropdown.isComponentVisible}
                routes={[{
                  label: 'PRICING RULES',
                  value: Routes.PRICING,
                }, {
                  label: 'HISTORIC SUMMARY',
                  value: Routes.PRICING_HISTORICAL_RULES,
                }, {
                  label: 'PENDING RULES',
                  value: Routes.PRICING_PENDING_RULES,
                },
                ]}
              />
            </div>
            <div
              css={styles.inactiveLink}
              onClick={() => partnersDropdown.setIsComponentVisible(!partnersDropdown.isPartnersComponentVisible)}
              ref={partnersDropdown.ref}
            >
              <PartnerAdminDropdown
                baseRoute={Routes.PARTNERS}
                dropdownTitle={'PARTNERS'}
                isOpen={partnersDropdown.isComponentVisible}
                routes={[{
                  label: 'PAYOUT PARTNERS',
                  value: Routes.PARTNERS,
                }, {
                  label: 'USERS',
                  value: Routes.USERS,
                },
                {
                  label: 'PARTNER EARNINGS',
                  value: Routes.PARTNER_EARNINGS,
                },
                ]}
              />
            </div>
          </div>
          <div
            css={styles.signout}
            onClick={handleLogout}
          >
            Sign Out
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 63,
    zIndex: ZIndex.HEADER,
    background: Colors.white(),
  },
  container: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  rightSideNav: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'uppercase',
    ...Theme.paragraph2(),
  },
  links: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    borderRight: '1px solid #767676',
  },
  inactiveLink: {
    cursor: 'pointer',
    padding: 10,
    ':visited': {
      color: Colors.gray50(),
    },
    ':hover': {
      color: Colors.rootOrange(),
      textDecoration: 'none',
    },
  },
  activeLink: {
    cursor: 'pointer',
    color: Colors.rootOrange(),
    padding: 10,
    ':hover': {
      color: Colors.rootOrange(),
      textDecoration: 'none',
    },
  },
  signout: {
    paddingLeft: 20,
    cursor: 'pointer',
    ':hover': {
      color: Colors.rootOrange(),
    },
  },
});

PartnerProgramHeader.propTypes = {
  activeScene: PropTypes.string,
  children: PropTypes.node,
};
