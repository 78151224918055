import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import dayjs from '@root/vendor/dayjs';
import sortBy from '@root/vendor/lodash/sortBy';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { EventTypes, costDisplayValue } from '@root/partners-common/src/models/partner-payment-event';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';

export default function DialogTable({
  activeRules, conflictingPendingRules, newRules,
}) {
  const rules = sortBy(
    newRules.map((newRule) => {
      const activeRule = activeRules.find((r) => r.market === newRule.market && r.partner.id === newRule.partner.id);
      const conflictingPendingRule = conflictingPendingRules.find((r) => r.market === newRule.market && r.partner.id === newRule.partner.id);
      return {
        activeRule,
        conflictingPendingRule,
        newRule,
      };
    }), [(r) => [r.conflictingPendingRule ? 0 : 1, r.newRule.partner, r.newRule.market]]);

  return (
    <div css={styles.tableContainer}>
      <table css={styles.table}>
        <thead css={styles.tableHead}>
          <tr>
            <th width={'130px'}>Partner Type</th>
            <th width={'110px'}>Partner</th>
            <th width={'115px'}>Market</th>
            <th width={'185px'}>Pricing Type</th>
            <th width={'90px'}>Event 1</th>
            <th width={'100px'}>Event 1 Cost</th>
            <th width={'90px'}>Event 2</th>
            <th width={'100px'}>Event 2 Cost</th>
            <th width={'100px'}>Effective</th>
          </tr>
        </thead>
        <tbody>
          {rules.map((r, i) => (
            <React.Fragment key={i}>
              {r.conflictingPendingRule && (
                <>
                  <tr css={styles.overrideRow}>
                    <td css={styles.mergedCell} />
                    <td css={styles.mergedCell} />
                    <td css={[styles.mergedCell, styles.finalMergedCell]} />

                    <td><span css={styles.cancelBadge}>CANCELING</span>{PricingTypes.MAPPING[r.conflictingPendingRule.pricingType]}</td>
                    <td>{EventTypes.displayValue(r.conflictingPendingRule.events[0])}</td>
                    <td>{costDisplayValue(r.conflictingPendingRule.events[0])}</td>
                    <td>{EventTypes.displayValue(r.conflictingPendingRule.events[1])}</td>
                    <td>{costDisplayValue(r.conflictingPendingRule.events[1])}</td>
                    <td>{dayjs(r.conflictingPendingRule.effectiveDate).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr
                    css={styles.overrideRow}
                    key={`expiringRow${i}`}
                  >
                    <td css={styles.middleMergedCell}><span>{r.newRule.partner.partnerType.name}</span></td>
                    <td css={styles.middleMergedCell}><span>{r.newRule.partner.name}</span></td>
                    <td css={[styles.mergedCell, styles.finalMergedCell]}><span>{Market.MAPPING[r.newRule.market]}</span></td>

                    <td><span css={styles.expiredBadge}>EXPIRING</span>{PricingTypes.MAPPING[r.activeRule.pricingType]}</td>
                    <td>{EventTypes.displayValue(r.activeRule.events[0])}</td>
                    <td>{costDisplayValue(r.activeRule.events[0])}</td>
                    <td>{EventTypes.displayValue(r.activeRule.events[1])}</td>
                    <td>{costDisplayValue(r.activeRule.events[1])}</td>
                    <td>{dayjs(r.activeRule.effectiveDate).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr
                    css={styles.overrideRow}
                    key={`newRow${i}`}
                  >
                    <td />
                    <td />
                    <td css={styles.finalMergedCell} />

                    <td><span css={styles.newBadge}>NEW</span>{PricingTypes.MAPPING[r.newRule.pricingType]}</td>
                    <td>{EventTypes.displayValue(r.newRule.events[0])}</td>
                    <td>{costDisplayValue(r.newRule.events[0])}</td>
                    <td>{EventTypes.displayValue(r.newRule.events[1])}</td>
                    <td>{costDisplayValue(r.newRule.events[1])}</td>
                    <td>{dayjs(r.newRule.effectiveDate).format('MMM D, YYYY')}</td>
                  </tr>
                </>
              )}
              {!r.conflictingPendingRule && (
                <>
                  <tr
                    css={i % 2 === 0 ? styles.evenRow : styles.oddRow}
                    key={`expiringRow${i}`}
                  >
                    <td css={styles.mergedCell}><span>{r.newRule.partner.partnerType.name}</span></td>
                    <td css={styles.mergedCell}><span>{r.newRule.partner.name}</span></td>
                    <td css={[styles.mergedCell, styles.finalMergedCell]}><span>{Market.MAPPING[r.newRule.market]}</span></td>

                    <td><span css={styles.expiredBadge}>EXPIRING</span>{PricingTypes.MAPPING[r.activeRule.pricingType]}</td>
                    <td>{EventTypes.displayValue(r.activeRule.events[0])}</td>
                    <td>{costDisplayValue(r.activeRule.events[0])}</td>
                    <td>{EventTypes.displayValue(r.activeRule.events[1])}</td>
                    <td>{costDisplayValue(r.activeRule.events[1])}</td>
                    <td>{dayjs(r.activeRule.effectiveDate).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr
                    css={i % 2 === 0 ? styles.evenRow : styles.oddRow}
                    key={`newRow${i}`}
                  >
                    <td />
                    <td />
                    <td css={styles.finalMergedCell} />

                    <td><span css={styles.newBadge}>NEW</span>{PricingTypes.MAPPING[r.newRule.pricingType]}</td>
                    <td>{EventTypes.displayValue(r.newRule.events[0])}</td>
                    <td>{costDisplayValue(r.newRule.events[0])}</td>
                    <td>{EventTypes.displayValue(r.newRule.events[1])}</td>
                    <td>{costDisplayValue(r.newRule.events[1])}</td>
                    <td>{dayjs(r.newRule.effectiveDate).format('MMM D, YYYY')}</td>
                  </tr>
                </>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const styles = StyleSheet.create({
  mergedCell: {
    'tr td&': {
      textAlign: 'left',
      borderBottom: '0px none',
      paddingLeft: 11,
      span: {
        position: 'relative',
        top: 20,
      },
    },
  },
  finalMergedCell: {
    'tr td&': {
      textAlign: 'left',
      borderRight: `1px solid ${Colors.gray30()}`,
      padding: 0,
      paddingLeft: 11,
    },
  },
  overrideRow: {
    backgroundColor: '#FDF0E9',
    '& td:first-of-type': {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        borderLeft: `3px solid ${Colors.rootOrange()}`,
      },
    },
    '& td:last-of-type': {
      color: Colors.rootOrange(),
    },

    'table tr& td': {
      span: {
        top: 0,
      },
    },
  },
  middleMergedCell: {
    'tr td&': {
      textAlign: 'left',
      border: '0px none',
      paddingLeft: 11,
    },
  },
  table: {
    borderCollapse: 'collapse',
    color: Colors.gray50(),
    fontSize: 13.5,
    width: 965,
    th: {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 11,
      border: `1px solid ${Colors.gray30()}`,
      borderLeft: '0px none',
      textAlign: 'left',
      fontWeight: 'normal',
      '&:first-of-type': {
        borderLeft: `1px solid ${Colors.gray30()}`,
      },
    },
    td: {
      paddingLeft: 11,
      borderBottom: `1px solid ${Colors.gray30()}`,
      paddingTop: 8,
      paddingBottom: 8,
      '&:first-of-type': {
        borderLeft: `1px solid ${Colors.gray30()}`,
      },
      '&:last-child': {
        borderRight: `1px solid ${Colors.gray30()}`,
      },
    },
  },
  tableHead: {
    backgroundColor: Colors.nearWhite(),
  },
  expiredBadge: {
    color: Colors.gray50(),
    fontSize: 9.5,
    marginRight: 27,
    fontWeight: 'bold',
  },
  newBadge: {
    backgroundColor: Colors.lightGreen(),
    color: 'white',
    padding: '3px 9px',
    fontSize: 9.5,
    borderRadius: 2,
    marginRight: 28,
    fontWeight: 'bold',
  },
  cancelBadge: {
    color: Colors.red(),
    fontSize: 9.5,
    marginRight: 15,
    fontWeight: 'bold',
  },
  evenRow: {
    background: Colors.nearWhite(),
  },
  oddRow: {
    background: Colors.white(),
  },
  tableContainer: {
    overflow: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: '5px 0',
  },
});

const rulesPropType = PropTypes.arrayOf(PropTypes.shape({
  effectiveDate: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({
    eventType: PropTypes.string.isRequired,
    paymentAmount: PropTypes.number.isRequired,
  })),
  market: PropTypes.string.isRequired,
  partner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    partnerType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  pricingType: PropTypes.string.isRequired,
}));

DialogTable.propTypes = {
  activeRules: rulesPropType.isRequired,
  conflictingPendingRules: rulesPropType.isRequired,
  newRules: rulesPropType.isRequired,
};
