import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function cancelPendingRulesConfiguration(pricingRules) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'pricing_rules/cancel_pending_rules',
    bodyParameters: {
      pricingRules,
    },
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.PUT,
    errorCodes: [422],
  });
}
