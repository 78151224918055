import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function deactivatePartnerUsersConfiguration({ userIds, deactivateAt }) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'users/deactivate',
    errorCodes: [500],
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.PUT,
    bodyParameters: {
      userIds,
      deactivateAt,
    },
  });
}
