import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import TriageRuleActivityList from '@root/partners.admin.joinroot.com/src/components/triage-rule-activity-list';
import closeButtonIcon from '@root/core/src/assets/closeModal.svg';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function PartnerSummaryPane({ triageRule, onClose }) {
  return (
    <div data-testid={'partner-summary-pane'}>
      <img
        alt={'Close'}
        css={styles.closeIcon}
        onClick={onClose}
        src={closeButtonIcon}
      />
      <div css={styles.partnerName}>{triageRule.partner.name}</div>
      <div css={styles.partnerMarket}>{Market.MAPPING[triageRule.market]}</div>
      <TriageRuleActivityList triageRuleId={triageRule.id} />
    </div>
  );
}

const styles = StyleSheet.create({
  partnerName: {
    ...Theme.heading3(),
  },
  partnerMarket: {
    ...Theme.paragraph2(),
    marginBottom: 30,
  },
  closeIcon: {
    position: 'absolute',
    right: 20,
    top: 16,
    cursor: 'pointer',
    width: 17,
  },
});

PartnerSummaryPane.propTypes = {
  onClose: PropTypes.func.isRequired,
  triageRule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    market: PropTypes.string.isRequired,
    partner: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
