import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function getPartnersConfiguration({ partnerTypeIds = [], includeInactive = false } = {}) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'partners',
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.GET,
    queryParameters: {
      partnerTypeIds,
      includeInactive,
    },
  });
}
