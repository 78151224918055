import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import TitledDropdown from '@root/internal-dashboards-core/src/components/titled-dropdown';
import useEscOrClickAwayToClose from '@root/internal-dashboards-core/src/hooks/use-esc-or-click-away-to-close';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';

export default function PricingTypeSelection({ onChange }) {
  const {
    ref, isComponentVisible, setIsComponentVisible,
  } = useEscOrClickAwayToClose();
  const [pricingTypeTitle, setPricingTypeTitle] = useState(null);

  return (
    <>
      <div css={styles.selectLabel}>
        {'Pricing Type'}
      </div>
      <div
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        ref={ref}
      >
        <TitledDropdown
          buttonStyle={[styles.selectBoxStyles, !pricingTypeTitle ? {
            color: Colors.gray60(),
          } : null]}
          chevronStyle={styles.chevronStyle}
          closedButtonStyle={styles.selectBoxStyles}
          closedChevronColor={Colors.gray40()}
          contents={PricingTypes.OPTIONS}
          isOpen={isComponentVisible}
          onClick={(value, label) => {
            onChange(value);
            setPricingTypeTitle(label);
          }}
          title={pricingTypeTitle || 'Choose type'}
        />
      </div>
    </>
  );
}

PricingTypeSelection.propTypes = {
  onChange: PropTypes.func,
};

const styles = StyleSheet.create({
  selectLabel: {
    color: Colors.gray50(),
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 3,
  },
  selectBoxStyles: {
    height: 34,
    width: 130,
    borderRadius: 4,
    fontSize: 14,
    margin: 'auto',
    backgroundColor: Colors.white(),
    color: Colors.black(),
    border: `1px solid ${Colors.gray20()}`,
    ...Responsive.lessThanXl({
      width: '100%',
    }),
  },
});
