import getUsersConfiguration from '@root/partners.admin.joinroot.com/src/api/users/get-users-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useCallback, useEffect, useState } from '@root/vendor/react';

export default function useUsers(includeInactive = false) {
  const [users, setUsers] = useState([]);
  const [userRequest, isLoading] = useImperativeNetworkRequest(getUsersConfiguration);

  const refresh = useCallback(async () => {
    const result = await userRequest(includeInactive);
    if (result.isSuccess()) {
      setUsers(result.data.partnerUsers);
    }
  }, [userRequest, includeInactive]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [users, isLoading, refresh];
}
