import getAllMarketsConfiguration from '@root/partners.admin.joinroot.com/src/api/get-all-markets/get-all-markets-configuration';
import getPartnersConfiguration from '@root/partners.admin.joinroot.com/src/api/partners/get-partners-configuration';
import uniqBy from '@root/vendor/lodash/uniqBy';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { RootError } from '@root-common/root-errors';
import { useCallback, useMemo, useState } from '@root/vendor/react';

const INITIAL_MARKET_DATA = {
  supportedMarkets: [],
  hybridMarkets: [],
  customerChoiceMarkets: [],
};

const INITIAL_PARTNER_DATA = {
  partners: [],
  partnerTypes: [],
};

const usePartnerAndMarketData = (authenticated) => {
  const [state, setState] = useState({
    ...INITIAL_MARKET_DATA,
    ...INITIAL_PARTNER_DATA,
  });
  const [marketConfig, setMarketConfig] = useState(getAllMarketsConfiguration);
  const [partnersConfig, setPartnersConfig] = useState(getPartnersConfiguration);

  const areMarketsLoading = useNetworkRequest(authenticated ? marketConfig : undefined, useCallback((result) => {
    if (result?.isSuccess()) {
      const marketData = result.data;

      setState((original) => ({
        ...original,
        supportedMarkets: marketData.supported,
        hybridMarkets: marketData.hybrid,
        customerChoiceMarkets: marketData.customerChoice,
      }));
    }
  }, []));

  const arePartnersLoading = useNetworkRequest(authenticated ? partnersConfig : undefined, useCallback((result) => {
    if (result?.isSuccess()) {
      const uniquePartnerTypes = uniqBy(result.data.partners.map((partner) => partner.partnerType), 'id');

      setState((original) => ({
        ...original,
        partners: result.data.partners,
        partnerTypes: uniquePartnerTypes,
      }));
    }
  }, []));

  const networkCallsPending = areMarketsLoading || arePartnersLoading;

  const refresh = useCallback((key) => {
    if (key in INITIAL_MARKET_DATA) {
      setMarketConfig(getAllMarketsConfiguration());
    } else if (key in INITIAL_PARTNER_DATA) {
      setPartnersConfig(getPartnersConfiguration());
    } else {
      throw new RootError({
        message: `Invalid refresh key: ${key}`,
        name: 'InvalidPartnerAndMarketDataRefreshKey',
        fingerprint: ['Invalid refresh key'],
      });
    }
  }, []);

  return useMemo(() => ({
    ...state,
    refresh,
    networkCallsPending,
  }), [refresh, state, networkCallsPending]);
};

export default usePartnerAndMarketData;
