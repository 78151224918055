import CaretLoader from '@root/core/src/components/caret-loader';
import PropTypes from '@root/vendor/prop-types';
import ZIndex from '@root/core/src/utils/z-index';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function Loading({ loadingMessage }) {
  return (
    <div css={styles.lightBoxBackground}>
      <div css={styles.loaderContainer}>
        <CaretLoader />
        <p css={styles.loadingMessage}>{loadingMessage}</p>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  loadingMessage: {
    ...Theme.heading3(),
    marginTop: 38,
    maxWidth: 303,
  },
  lightBoxBackground: {
    background: 'rgba(0, 0, 0, 0.4)',
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    zIndex: ZIndex.MODAL_OVERLAY_BACKGROUND,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  loaderContainer: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

Loading.propTypes = {
  loadingMessage: PropTypes.string,
};
