import AbTestProvider from '@root/core/src/contexts/ab-test/ab-test-provider';
import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import ExperienceProvider from '@root/core/src/contexts/experience/experience-provider';
import Home from '@root/partners.admin.joinroot.com/src/scenes/home';
import OktaTokenAdapter from '@root/okta-auth/src/components/okta-token-adapter';
import PartnerEarnings from '@root/partners.admin.joinroot.com/src/scenes/partner-earnings';
import Partners from '@root/partners.admin.joinroot.com/src/scenes/partners';
import PartnersAndMarketsContextProvider from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context-provider';
import Pricing from '@root/partners.admin.joinroot.com/src/scenes/pricing';
import PricingHistoricalRules from '@root/partners.admin.joinroot.com/src/scenes/pricing-historical-rules';
import PricingPendingRules from '@root/partners.admin.joinroot.com/src/scenes/pricing-pending-rules';
import PropTypes from '@root/vendor/prop-types';
import React, { Suspense } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import ScrollToTop from '@root/core/src/components/scroll-to-top';
import Triage from '@root/partners.admin.joinroot.com/src/scenes/triage';
import Users from '@root/partners.admin.joinroot.com/src/scenes/users';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { LoginCallback, SecureRoute, Security } from '@root/vendor/@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@root/vendor/@okta/okta-auth-js';
import { Redirect, Route, Switch } from '@root/core/src/components/route';
import { useHistory } from '@root/vendor/react-router-dom';

const PARTNERS_ADMIN_APP_ANALYTICS_PREFIX = 'PARTNERS_ADMIN_APP';

const config = {
  issuer: `https://${ENV.OKTA_DOMAIN}/oauth2/default`,
  redirectUri: `${ENV.PARTNERS_ADMIN_APP_BASE_URL}/implicit/callback`,
  clientId: ENV.OKTA_CLIENT_ID,
  pkce: true,
};

const oktaAuth = new OktaAuth(config);

export const Routes = {
  HOME: '/',
  TRIAGE: '/triage',
  PARTNERS: '/partners',
  PARTNER_EARNINGS: '/partners/earnings',
  PRICING: '/pricing',
  PRICING_PENDING_RULES: '/pricing/pending',
  PRICING_HISTORICAL_RULES: '/pricing/historical',
  USERS: '/users',
};

function App() {
  useAnalytics('APP_ENTRY');

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, history.location.origin));
  };

  return (
    <ScrollToTop>
      <Suspense fallback={<SceneLoader hideHeader={true} />}>
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={restoreOriginalUri}
        >
          <OktaTokenAdapter />
          <PartnersAndMarketsContextProvider>
            <Switch>
              <SecureRoute
                component={Home}
                exact={true}
                path={Routes.HOME}
              />
              <SecureRoute
                component={Partners}
                exact={true}
                path={Routes.PARTNERS}
              />
              <SecureRoute
                component={PartnerEarnings}
                exact={true}
                path={Routes.PARTNER_EARNINGS}
              />
              <SecureRoute
                component={Pricing}
                exact={true}
                path={Routes.PRICING}
              />
              <SecureRoute
                component={PricingHistoricalRules}
                exact={true}
                path={Routes.PRICING_HISTORICAL_RULES}
              />
              <SecureRoute
                component={PricingPendingRules}
                exact={true}
                path={Routes.PRICING_PENDING_RULES}
              />
              <SecureRoute
                component={Triage}
                exact={true}
                path={Routes.TRIAGE}
              />
              <SecureRoute
                component={Users}
                exact={true}
                path={Routes.USERS}
              />
              <Route
                component={LoginCallback}
                path={'/implicit/callback'}
              />
              <Redirect to={Routes.HOME} />
            </Switch>
          </PartnersAndMarketsContextProvider>
        </Security>
      </Suspense>
    </ScrollToTop>
  );
}

export default function AppEntry({
  initialAbTests = {}, initialExperiences = {}, routerComponent: Router,
}) {
  return (
    <ErrorBoundary>
      <AbTestProvider initialAbTests={initialAbTests}>
        <ExperienceProvider initialExperiences={initialExperiences}>
          <AnalyticsProvider eventPrefix={PARTNERS_ADMIN_APP_ANALYTICS_PREFIX}>
            <Router>
              <App />
            </Router>
          </AnalyticsProvider>
        </ExperienceProvider>
      </AbTestProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  initialAbTests: PropTypes.object,
  initialExperiences: PropTypes.object,
  routerComponent: PropTypes.func.isRequired,
};

export { PARTNERS_ADMIN_APP_ANALYTICS_PREFIX };
