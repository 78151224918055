import PropTypes from '@root/vendor/prop-types';
import TitledDropdown from '@root/internal-dashboards-core/src/components/titled-dropdown';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { useHistory, useLocation } from '@root/vendor/react-router-dom';
import { useState } from '@root/vendor/react';

export default function PartnerAdminDropdown({
  baseRoute, isOpen, routes, dropdownTitle,
}) {
  const location = useLocation();
  const history = useHistory();
  const isActive = location.pathname.includes(baseRoute);
  const [isHovered, setIsHovered] = useState(false);
  const { trackClick } = useAnalytics(`${dropdownTitle}_DROPDOWN`);

  const handleSelection = (route, title) => {
    const formattedTitle = title.replace(/\s/g, '_');

    trackClick(formattedTitle);
    history.push(route);
  };

  return (
    <div
      className={'partner-admin-dropdown'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TitledDropdown
        buttonStyle={isActive ? styles.activeDropdown : styles.dropdown}
        chevronStyle={styles.chevron}
        closedChevronColor={isHovered || isActive ? Colors.rootOrange() : Colors.gray50()}
        contents={routes}
        contentStyle={styles.openSelection}
        isOpen={isOpen}
        onClick={(route, title) => handleSelection(route, title)}
        openChevronColor={Colors.rootOrange()}
        title={dropdownTitle}
      />
    </div>
  );
}

PartnerAdminDropdown.propTypes = {
  baseRoute: PropTypes.string.isRequired,
  dropdownTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  routes: PropTypes.array.isRequired,
};

const partnerAdminDropdownStyle = {
  width: 65,
  backgroundColor: 'transparent',
  color: Colors.gray50(),
  border: 0,
  padding: 0,
  '.partner-admin-dropdown:hover &': {
    color: Colors.rootOrange(),
    textDecoration: 'none',
  },
};

const styles = StyleSheet.create({
  activeDropdown: {
    ...partnerAdminDropdownStyle,
    color: Colors.rootOrange(),
  },
  dropdown: partnerAdminDropdownStyle,
  openSelection: {
    borderRadius: 2,
    color: Colors.gray50(),
    ':hover': {
      color: Colors.rootOrange(),
      textDecoration: 'none',
      backgroundColor: Colors.white(),
    },
  },
  chevron: {
    width: 16,
    marginLeft: 5,
  },
});
