import Datepicker from '@root/core/src/components/datepicker';
import EventPricing from '@root/partners.admin.joinroot.com/src/components/event-pricing';
import PricingTypeSelection from '@root/partners.admin.joinroot.com/src/components/pricing-type-selection';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import dayjs from '@root/vendor/dayjs';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Strategies } from '@root/partners-common/src/models/partner-payment-event';
import { dayPickerInputStyles, dayPickerStyles } from '@root/partners.admin.joinroot.com/src/utils/partner-admin-datepicker-styles';

const paymentEventsValid = (paymentEvents) => {
  return paymentEvents.every((paymentEvent) => {
    return paymentEvent.eventType && paymentEvent.paymentAmount;
  });
};

const inputsFilled = (pricingType, effectiveDate, paymentEvents) => {
  return pricingType && effectiveDate && paymentEventsValid(paymentEvents);
};

const handleAddEvent = (maxPricingEvents, paymentEvents, setPricingEvents) => {
  if (paymentEvents.length === maxPricingEvents) { return; }
  setPricingEvents([...paymentEvents, {
    precedence: paymentEvents.length,
  }]);
};

const updateInitializePricingEvent = (precedence, paymentEvents) => {
  const events = [...paymentEvents];
  if (!events[precedence]) {
    events[precedence] = {};
  }
  return events;
};

export default function PricingRuleCreate({ onApplyRules }) {
  const maxPricingEvents = 2;
  const maxPaymentAmount = 500,
    minPaymentAmount = 0;

  const [pricingType, setPricingType] = useState('');
  const [paymentEvents, setPricingEvents] = useState([{
    precedence: 0,
    eventType: '',
    paymentAmount: '',
  }]);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const { trackClick } = useAnalytics('PRICING_RULE_CREATE', false);

  const handleRemoveEvent = () => {
    setPricingEvents(paymentEvents.slice(0, paymentEvents.length - 1));
  };

  const handlePricingTypeChange = (eventType) => {
    setPricingType(eventType);
    if (eventType === 'no_payment') {
      setPricingEvents([]);
    } else if (paymentEvents.length === 0) {
      handleAddEvent(maxPricingEvents, paymentEvents, setPricingEvents);
    }
  };

  const handleEventChange = (eventType, precedence) => {
    const events = updateInitializePricingEvent(precedence, paymentEvents);
    events[precedence].eventType = eventType;

    setPricingEvents(events);
  };

  const handleAmountChange = (paymentAmount, precedence) => {
    const events = updateInitializePricingEvent(precedence, paymentEvents);
    const clampedValue = Math.min(Math.max(paymentAmount, minPaymentAmount), maxPaymentAmount);
    events[precedence].paymentAmount = clampedValue;

    setPricingEvents(events);
  };

  const handleDateChange = (date) => {
    setEffectiveDate(date);
  };

  const handleApplyRules = () => {
    const data = {
      pricingType,
      effectiveDate: effectiveDate.toISOString().slice(0, 10),
      events: paymentEvents.map((event) => ({
        ...event,
        strategy: Strategies.fromPricingType(pricingType),
        options: { amount: event.paymentAmount },
      })),
    };
    trackClick('APPLY_NEW_RULES', {
      data,
    });
    onApplyRules(data);
  };

  return (
    <div css={styles.container}>
      <div css={styles.newRuleLabel}>New Rule</div>
      <div css={styles.item}>
        <PricingTypeSelection onChange={handlePricingTypeChange} />
      </div>

      {paymentEvents.map((paymentEvent, i) => {
        const showRemove = paymentEvent.precedence !== 0 && paymentEvent.precedence === paymentEvents.length - 1;
        return (
          <EventPricing
            handleAmountChange={handleAmountChange}
            handleEventChange={handleEventChange}
            handleRemoveEvent={handleRemoveEvent}
            key={i}
            paymentEvent={paymentEvent}
            pricingType={pricingType}
            showRemove={showRemove}
          />
        );
      })}
      {
        pricingType !== 'no_payment' && paymentEvents.length !== maxPricingEvents &&
        <button
          css={[styles.item, styles.button, styles.eventButton]}
          onClick={() => handleAddEvent(maxPricingEvents, paymentEvents, setPricingEvents)}
        >{'Add Event'}
        </button>
      }
      <div css={styles.item}>
        <div css={styles.selectLabel}>
          {'Effective Date'}
        </div>
        <Datepicker
          data-testid={'effective-date'}
          disablePastDays={true}
          id={'effective-date'}
          inputId={'effective-date'}
          inputStyles={dayPickerInputStyles}
          label={''}
          onDateChange={handleDateChange}
          pickerStyles={dayPickerStyles}
          today={dayjs().add(1, 'day').toDate()}
        />
      </div>

      {inputsFilled(pricingType, effectiveDate, paymentEvents) && (
        <PrimaryButton
          css={styles.applyRuleButton}
          onClick={handleApplyRules}
        >
          {'Apply new rules'}
        </PrimaryButton>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 7,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 12,
    ...Responsive.lessThanXl({
      alignItems: 'stretch',
      maxWidth: 400,
      paddingLeft: 6,
      flexDirection: 'column',
    }),
  },
  item: {
    marginRight: 6,
    ...Responsive.lessThanXl({
      marginTop: 6,
      marginRight: 0,
    }),
  },
  newRuleLabel: {
    color: Colors.gray50(),
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 24,
    marginRight: 40,
    ...Responsive.lessThanXl({
      padding: 10,
      marginLeft: 0,
    }),
  },
  eventButton: {
    color: Colors.rootOrange(),
    border: `1px solid ${Colors.rootOrange()}`,
    backgroundColor: Colors.white(),
    paddingLeft: 14,
    paddingRight: 14,
    marginRight: 12,
    marginLeft: 6,
    marginTop: 'auto',
    height: 34,
    borderRadius: 3,
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    ':focus': {
      outline: 'none',
    },
    ...Responsive.lessThanXl({
      marginLeft: 'auto',
      marginTop: 6,
      width: '100%',
    }),
  },
  applyRuleButton: {
    right: 30,
    position: 'absolute',
    width: 176,
    marginTop: 9,
    ...Responsive.lessThanXl({
      marginTop: 6,
      right: 0,
      position: 'relative',
      width: '100%',
    }),
  },
  selectLabel: {
    color: Colors.gray50(),
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 3,
  },
});

PricingRuleCreate.propTypes = {
  onApplyRules: PropTypes.func.isRequired,
};
