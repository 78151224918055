import Market from '@root/core/src/models/market';
import MultiSelectList from '@root/internal-dashboards-core/src/components/multi-select-list';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';

function RuleFilters({
  onChange, earnedMonths = [], markets = [], partners = [], partnerTypes = [],
}) {
  const [selectedPartnerTypes, setSelectedPartnerTypes] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedEarnedMonths, setSelectedEarnedMonths] = useState([]);

  useEffect(() => {
    onChange([
      {
        id: 'partnerId',
        value: selectedPartners,
      },
      {
        id: 'marketId',
        value: selectedMarkets,
      },
      {
        id: 'earnedMonth',
        value: selectedEarnedMonths,
      },
    ]);
  }, [onChange, selectedPartners, selectedMarkets, selectedEarnedMonths]);

  const handlePartnersChange = (partnerId) => {
    const wasUnselected = selectedPartners.includes(partnerId);
    let selected;
    if (wasUnselected) {
      selected = selectedPartners.filter((item) => item !== partnerId);
    } else {
      selected = selectedPartners.concat(partnerId);
    }
    const partnerToChange = partners.find((item) => item.id === partnerId);
    const allPartnersOfType = partners.filter((item) => item.partnerType.id === partnerToChange.partnerType.id);

    const allPartnersOfTypeAreSelected = allPartnersOfType.every((partner) => selected.includes(partner.id));

    if (allPartnersOfTypeAreSelected) {
      setSelectedPartnerTypes(selectedPartnerTypes.concat(partnerToChange.partnerType.id));
    } else {
      setSelectedPartnerTypes(selectedPartnerTypes.filter((item) => item !== partnerToChange.partnerType.id));
    }

    setSelectedPartners(selected);
  };

  const handlePartnerTypesChange = (partnerTypeId) => {
    let selected;
    if (selectedPartnerTypes.includes(partnerTypeId)) {
      selected = selectedPartnerTypes.filter((item) => item !== partnerTypeId);
      const partnersToRemove = partners.filter((item) => partnerTypeId === item.partnerType.id).map((item) => item.id);
      setSelectedPartners(selectedPartners.filter((item) => !partnersToRemove.includes(item)));
    } else {
      selected = selectedPartnerTypes.concat(partnerTypeId);
      const selectedPartnersList = selectedPartners.slice();
      partners.forEach((item) => { if (item.partnerType.id === partnerTypeId && !selectedPartnersList.includes(item.id)) { selectedPartnersList.push(item.id); } });
      setSelectedPartners(selectedPartnersList);
    }
    setSelectedPartnerTypes(selected);
  };

  const handleMarketsChange = (marketId) => {
    let selected;
    if (selectedMarkets.includes(marketId)) {
      selected = selectedMarkets.filter((item) => item !== marketId);
    } else {
      selected = selectedMarkets.concat(marketId);
    }
    setSelectedMarkets(selected);
  };

  const handleEarnedMonthsChange = (earnedMonth) => {
    let selected;
    if (selectedEarnedMonths.includes(earnedMonth)) {
      selected = selectedEarnedMonths.filter((item) => item !== earnedMonth);
    } else {
      selected = selectedEarnedMonths.concat(earnedMonth);
    }
    setSelectedEarnedMonths(selected);
  };

  const handleSelectAllClick = (isSelectAll) => {
    if (isSelectAll) {
      setSelectedPartnerTypes(partnerTypes.map((item) => item.id));
      setSelectedPartners(partners.map((item) => item.id));
    } else {
      setSelectedPartnerTypes([]);
      setSelectedPartners([]);
    }
  };

  const handleSelectAllMarkets = (isSelectAll) => {
    if (isSelectAll) {
      setSelectedMarkets(markets);
    } else {
      setSelectedMarkets([]);
    }
  };

  const handleSelectAllEarnedMonths = (isSelectAll) => {
    if (isSelectAll) {
      setSelectedEarnedMonths(earnedMonths);
    } else {
      setSelectedEarnedMonths([]);
    }
  };

  return (
    <>
      <MultiSelectList
        listValues={partnerTypes}
        onItemClick={handlePartnerTypesChange}
        onSelectAllClick={handleSelectAllClick}
        selectedItems={selectedPartnerTypes}
        title={'Partner Types'}
      />
      <MultiSelectList
        listValues={partners}
        onItemClick={handlePartnersChange}
        onSelectAllClick={handleSelectAllClick}
        selectedItems={selectedPartners}
        title={'Partners'}
      />
      {markets.length > 0 && (
        <MultiSelectList
          listValues={markets.map((market) => {
            return {
              id: market,
              name: Market.MAPPING[market],
            };
          })}
          onItemClick={handleMarketsChange}
          onSelectAllClick={handleSelectAllMarkets}
          selectedItems={selectedMarkets}
          title={'Markets'}
        />
      )}
      {earnedMonths.length > 0 && (
        <MultiSelectList
          listValues={earnedMonths.map((earnedMonth) => {
            return {
              id: earnedMonth,
              name: earnedMonth,
            };
          })}
          onItemClick={handleEarnedMonthsChange}
          onSelectAllClick={handleSelectAllEarnedMonths}
          selectedItems={selectedEarnedMonths}
          title={'Earned Months'}
        />
      )}
    </>
  );
}

RuleFilters.propTypes = {
  earnedMonths: PropTypes.array,
  markets: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  partners: PropTypes.array,
  partnerTypes: PropTypes.array,
};

export default React.memo(RuleFilters);
