import ConfirmationModal from '@root/partners.admin.joinroot.com/src/components/confirmation-modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import alertFilled from '@root/core/src/assets/alert-filled.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

function Header({ effectiveDate }) {
  return (
    <>
      <h1 css={styles.heading}>
        <img src={alertFilled} />
        System Change
      </h1>
      <p css={styles.paragraph}>Users with the following emails will be deactivated effective on: <span css={styles.date}>{effectiveDate}</span></p>
    </>
  );
}

export default function PartnerUserDeactivationConfirmationModal({
  effectiveDate,
  onCancel,
  onConfirm,
  users = [],
}) {
  const { trackClick } = useAnalytics('PARTNER_USER_DEACTIVATION_CONFIRMATION', false);

  const handleCancel = () => {
    trackClick('CANCEL');
    onCancel();
  };

  const handleConfirm = () => {
    trackClick('APPLY');
    onConfirm();
  };

  return (
    <ConfirmationModal
      header={<Header effectiveDate={effectiveDate} />}
      id={'partner-user-deactivation-modal'}
      onCancel={handleCancel}
      onClose={onCancel}
      onConfirm={handleConfirm}
    >
      <ul>
        {users.map((user) =>
          (
            <li
              css={styles.paragraph}
              key={user.id}
            >
              {user.email}
            </li>
          )
        )}
      </ul>
    </ConfirmationModal>
  );
}

Header.propTypes = {
  effectiveDate: PropTypes.string.isRequired,
};

PartnerUserDeactivationConfirmationModal.propTypes = {
  effectiveDate: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })).isRequired,
};

const styles = StyleSheet.create({
  heading: {
    ...Theme.heading1(),
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 6,
    },
  },
  paragraph: {
    ...Theme.paragraph1(),
  },
  date: {
    color: Colors.rootOrange(),
  },
});
