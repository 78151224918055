import * as RootSans from '@root/brand/src/assets/fonts/root-sans/emotion';
import PartnerAdminWrapper from '@root/partners.admin.joinroot.com/src/components/partners-admin-wrapper';
import React, { useState } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useResize from '@root/core/src/hooks/use-resize';
import { Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';

import RootPartnerProgram from '@root/partners.admin.joinroot.com/src/assets/icons/root-partner-program.svg';

export default function Home() {
  useAnalytics('HOME');
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useResize({
    onResize: () => {
      setInnerHeight(window.innerHeight);
    },
  });

  return (
    <PartnerAdminWrapper>
      <hr css={styles.break} />
      <div
        css={[styles.background, {
          height: innerHeight - 73,
        }]}
      >
        <div css={styles.welcomeBox}>
          <div>
            <div css={styles.welcomeToText}>
              {'Welcome to the'}
            </div>
            <img
              alt={'Welcome to the Root Partner Program Admin'}
              src={RootPartnerProgram}
            />
            <div
              css={styles.adminText}
            >
              {'ADMIN'}
            </div>
          </div>
        </div>
      </div>
    </PartnerAdminWrapper>
  );
}

const styles = StyleSheet.create({
  background: {
    paddingTop: 130,
    background: 'linear-gradient(180deg,#fafafa 0%,#dadada 100%)',
    display: 'flex',
    justifyContent: 'center',
  },
  break: {
    borderTop: '1px solid #dadada',
  },
  welcomeBox: {
    width: 430,
    height: 290,
    backgroundColor: Colors.white(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...Shadows.normal(),
  },
  welcomeToText: {
    ...RootSans.light(),
    color: '#8394b4',
    fontSize: 15,
    lineHeight: '200%',
  },
  adminText: {
    color: Colors.rootOrange(),
    fontSize: 15,
    textAlign: 'right',
    lineHeight: '200%',
  },
});
