import DashboardLayout from '@root/partners.admin.joinroot.com/src/components/dashboard-layout';
import HeaderLayout from '@root/partners.admin.joinroot.com/src/components/header-layout';
import Loading from '@root/partners.admin.joinroot.com/src/components/loading';
import PartnerAdminWrapper from '@root/partners.admin.joinroot.com/src/components/partners-admin-wrapper';
import PartnerEarningsAdjust from '@root/partners.admin.joinroot.com/src/components/partner-earnings-adjust';
import PartnerEarningsAdjustConfirmationModal from '@root/partners.admin.joinroot.com/src/components/partner-earnings-adjust-confirmation-modal.js';
import PartnerEarningsTable from '@root/partners.admin.joinroot.com/src/components/partner-earnings-table';
import PartnersAndMarketsContext from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context';
import React, { useCallback, useContext, useState } from '@root/vendor/react';
import RuleFilters from '@root/partners.admin.joinroot.com/src/components/rule-filters';
import Toast, { errorToast, successToast } from '@root/partners.admin.joinroot.com/src/components/toast';
import adjustPartnerEarningsConfiguration from '@root/partners.admin.joinroot.com/src/api/adjust-partner-earnings/adjust-partner-earnings-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import usePartnerEarnings from '@root/partners.admin.joinroot.com/src/hooks/use-partner-earnings';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function PartnerEarnings() {
  const {
    partners, partnerTypes, networkCallsPending,
  } = useContext(PartnersAndMarketsContext);
  const [adjustEarnings, isAdjustingEarnings] = useImperativeNetworkRequest(adjustPartnerEarningsConfiguration);

  const [partnerEarnings, isPartnerEarningsLoading, refreshEarnings] = usePartnerEarnings();
  const uniqueEarnedMonths = [...new Set(partnerEarnings.map((partnerEarning) => partnerEarning.earnedMonth))];

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedPartnerEarnings, setSelectedPartnerEarnings] = useState([]);
  const [earningAdjustmentAmount, setEarningAdjustmentAmount] = useState(0);
  const [toastMessage, setToastMessage] = useState();

  const isLoading = networkCallsPending || isPartnerEarningsLoading || isAdjustingEarnings;

  const handleRowSelect = useCallback((selectedRows) => {
    setSelectedPartnerEarnings(
      selectedRows.map((row) => partnerEarnings.find((partnerEarning) => partnerEarning.id === row.partnerEarningId))
    );
  }, [partnerEarnings]);

  const handleAdjustEarningsCancel = () => {
    setEarningAdjustmentAmount(0);
  };

  const handleAdjustEarningsConfirm = async () => {
    const result = await adjustEarnings({
      partnerAttributedEarningIds: selectedPartnerEarnings.map((pe) => pe.id),
      earningAdjustmentAmount,
    });

    setEarningAdjustmentAmount(0);

    if (result.isSuccess()) {
      await refreshEarnings();
      setToastMessage(successToast({ message: 'Partner Earning(s) Adjusted' }));
    } else {
      setToastMessage(errorToast({ message: 'There was an error adjusting partner earning(s)' }));
    }
  };

  return (
    <PartnerAdminWrapper>
      <HeaderLayout title={'PARTNER EARNINGS'} />
      {!isLoading && (
        <>
          <DashboardLayout
            filters={
              <RuleFilters
                earnedMonths={uniqueEarnedMonths}
                onChange={setSelectedFilters}
                partners={partners}
                partnerTypes={partnerTypes}
              />
            }
            table={
              <PartnerEarningsTable
                onRowSelect={handleRowSelect}
                partnerEarnings={partnerEarnings}
                partnerFilters={selectedFilters}
              />
            }
            tableHeader={
              selectedPartnerEarnings.length > 0 && (
                <div css={styles.tableHeader}>
                  <PartnerEarningsAdjust onApply={setEarningAdjustmentAmount} />
                </div>
              )
            }
          />

          {
            !!earningAdjustmentAmount &&
              <PartnerEarningsAdjustConfirmationModal
                earningAdjustmentAmount={earningAdjustmentAmount}
                onCancel={handleAdjustEarningsCancel}
                onConfirm={handleAdjustEarningsConfirm}
                partnerEarnings={selectedPartnerEarnings}
              />
          }

          {toastMessage && (
            <Toast
              analyticsContext={'PARTNER_EARNINGS'}
              toastMessage={toastMessage}
            />
          )}
        </>
      )}
      {isLoading && <Loading />}
    </PartnerAdminWrapper>
  );
}

const styles = StyleSheet.create({
  tableHeader: {
    padding: '12px 24px',
  },
});
