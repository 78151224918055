import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useRef } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useModal from '@root/core/src/hooks/use-modal';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

import alertFilled from '@root/core/src/assets/alert-filled.svg';

export default function TriageRuleChangeConfirmationModal({
  onCancel, onConfirm, shouldShow,
}) {
  const { trackClick } = useAnalytics('TRIAGE_RULE_CHANGE', false);
  const portalRef = useRef();

  const renderPortal = usePortal('triage-rule-change-confirmation');
  useModal(onCancel, portalRef);

  const handleConfirm = () => {
    trackClick('CONFIRM');
    onConfirm();
  };

  const handleCancel = () => {
    trackClick('CANCEL');
    onCancel();
  };

  return renderPortal(
    <>
      {shouldShow &&
      <div css={styles.cardWrapper}>
        <div
          css={styles.outerContent}
          ref={portalRef}
        >
          <div css={styles.content}>
            <div css={styles.heading}>
              <div css={styles.alertIcon}>
                <img src={alertFilled} />
              </div>
              <div css={styles.headingText}>
                System Change
              </div>
            </div>
            <div css={styles.message}>
              <span css={styles.caution}>Caution. </span>
              You are about to make changes that will impact pricing, terms, and conditions for existing partners.
            </div>
            <div css={styles.buttonArea}>
              <div css={styles.buttons}>
                <div
                  css={styles.cancel}
                  onClick={handleCancel}
                >
                  Cancel
                </div>
                <div css={styles.applyWrapper}>
                  <PrimaryButton
                    css={styles.button}
                    onClick={handleConfirm}
                  >{'Apply Update'}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
}

const styles = StyleSheet.create({
  cardWrapper: {
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 300,
    ...Responsive.lessThanSm({
      top: 48,
    }),
  },
  outerContent: {
    backgroundColor: Colors.white(),
    width: '100%',
    maxWidth: 600,
    display: 'flex',
    padding: 35,
    borderRadius: 4,
    ...Responsive.lessThanSm({
      height: '100%',
    }),
    ...Shadows.cardShadow(),
  },
  content: {
    marginLeft: 30,
    marginRight: 30,
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'horizontal',
    marginBottom: 20,
  },
  headingText: {
    ...semiBold(),
    fontSize: 18,
    lineHeight: '125%',
    color: Colors.nearBlack(),
  },
  alertIcon: {
    marginRight: 10,
  },
  message: {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '125%',
    color: Colors.gray50(),
  },
  caution: {
    ...semiBold(),
    color: Colors.rootOrange(),
  },
  buttonArea: {
    justifyContent: 'center',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
  },
  cancel: {
    textAlign: 'center',
    fontSize: 16,
    marginRight: 25,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
      color: Colors.rootOrange(),
    },
  },
  button: {
    position: 'relative',
    padding: '8px 24px',
  },
});

TriageRuleChangeConfirmationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  shouldShow: PropTypes.bool.isRequired,
};
