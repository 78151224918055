import OrangeGear from '@root/core/src/assets/icons/orange-gear';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Shadows from '@root/brand/src/utils/shadows';
import ZIndex from '@root/core/src/utils/z-index';
import useEscOrClickAwayToClose from '@root/internal-dashboards-core/src/hooks/use-esc-or-click-away-to-close';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function TriageRulesDisplay({ triageRules, isLoading }) {
  const {
    ref, isComponentVisible, setIsComponentVisible,
  } = useEscOrClickAwayToClose();

  const handleButtonClick = () => {
    if (isLoading) { return; }
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div
      css={styles.container}
      onClick={() => setIsComponentVisible(!isComponentVisible)}
      ref={ref}
    >
      <button
        css={styles.button}
        onClick={() => handleButtonClick()}
      >{isLoading ? 'Loading...' : 'Triage Rules'}
        <OrangeGear />
      </button>
      <div
        css={!isLoading && isComponentVisible ? styles.displayed : styles.hidden}
        data-testid={'triage-items'}
        onClick={() => handleButtonClick()}
      >
        {triageRules.map((rule) => {
          return (
            <div
              css={[styles.listItem, !rule.enabled ? styles.listItemDisabled : null]}
              key={rule.name}
            >
              <div>{rule.name}</div>
              {rule.enabled ? null : <div css={styles.disabled}>Disabled</div>}
            </div>);
        })
        }
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Root Sans',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    position: 'relative',
  },
  button: {
    color: Colors.rootOrange(),
    fontSize: 14,
    width: 175,
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${Colors.rootOrange()}`,
    backgroundColor: Colors.white(),
    cursor: 'pointer',
    padding: 7,
    ':focus': {
      outline: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
  displayed: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 40,
    backgroundColor: Colors.white(),
    boxShadow: Shadows.hoverShadow().boxShadow,
    borderRadius: 2,
    width: 350,
    zIndex: ZIndex.MODAL_OVERLAY_BACKGROUND,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'space-between',
    padding: 2,
    paddingLeft: 7,
    paddingRight: 7,
    ...Theme.paragraph2(),
    borderTop: `1px solid ${Colors.gray30()}`,
  },
  listItemDisabled: {
    fontSize: 14,
    color: Colors.gray20(),
  },
  disabled: {
    textTransform: 'uppercase',
    fontSize: 10,
    backgroundColor: Colors.gray20(),
    borderRadius: 2,
    paddingLeft: 4,
    paddingRight: 4,
    color: Colors.gray50(),
  },
});

TriageRulesDisplay.propTypes = {
  isLoading: PropTypes.bool,
  triageRules: PropTypes.array.isRequired,
};
