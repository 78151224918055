import getPricingRulesConfiguration from '@root/partners.admin.joinroot.com/src/api/get-pricing-rules/get-pricing-rules-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useCallback, useEffect, useState } from '@root/vendor/react';

export default function usePricingRules({ includeExpired = false } = {}) {
  const [pricingRules, setPricingRules] = useState([]);

  const [request, isLoading] = useImperativeNetworkRequest(getPricingRulesConfiguration);

  const refresh = useCallback(async () => {
    const result = await request({
      includeExpired,
    });
    if (result.isSuccess()) {
      result.data.pricingRules.sort((a, b) => a.id > b.id ? 1 : -1);
      setPricingRules(result.data.pricingRules);
    }
  }, [request, includeExpired]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [pricingRules, isLoading, refresh];
}

