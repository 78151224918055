import Modal from '@root/partners.admin.joinroot.com/src/components/modal';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Shadows from '@root/brand/src/utils/shadows';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function ConfirmationModal({
  children,
  confirmationText = 'Apply',
  header,
  id,
  onCancel,
  onClose = () => {},
  onConfirm,
}) {
  return (
    <Modal
      id={id}
      onClose={onClose}
    >
      {header && <>{header}</>}

      <div css={styles.body}>
        {children}
      </div>

      <div css={styles.footer}>
        <button
          css={styles.cancelButton}
          onClick={onCancel}
          type={'button'}
        >
          Cancel
        </button>

        <PrimaryButton
          css={styles.confirmationButton}
          onClick={onConfirm}
        >
          {confirmationText}
        </PrimaryButton>
      </div>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  children: PropTypes.node,
  confirmationText: PropTypes.string,
  header: PropTypes.node,
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
};

const button = {
  padding: '6px 20px',
};

const styles = StyleSheet.create({
  body: {
    overflow: 'auto',
    maxHeight: 500,
  },
  footer: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 20,
    marginTop: 10,
    borderTop: `1px solid ${Colors.gray20()}`,
  },
  cancelButton: {
    border: 'none',
    background: 'transparent',
    ...button,
    ':hover': {
      ...Theme.textLink(),
    },
    ':focus': {
      outline: 'none',
    },
  },
  confirmationButton: {
    ...Shadows.normal(),
    ...button,
  },
});
