import PartnerForm from '@root/partners.admin.joinroot.com/src/components/partner-form';
import PropTypes from '@root/vendor/prop-types';
import React, { useRef } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import ZIndex from '@root/core/src/utils/z-index';
import useModal from '@root/core/src/hooks/use-modal';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function PartnerModal({
  onCancel, onConfirm, partner, shouldShow,
}) {
  const portalRef = useRef();

  const renderPortal = usePortal('add-partner');
  useModal(onCancel, portalRef);

  const titlePrefix = partner === null || partner === undefined ? 'Add ' : 'Edit ';

  return renderPortal(
    <>
      {shouldShow &&
        <div css={styles.cardWrapper}>
          <div
            css={styles.outerContent}
            ref={portalRef}
          >
            <div css={styles.heading}>
              <div css={styles.headingText}>
                {titlePrefix + 'Payout Partner'}
              </div>
            </div>
            <PartnerForm
              onCancel={onCancel}
              onConfirm={onConfirm}
              partner={partner}
            />
          </div>
        </div>
      }
    </>
  );
}

const styles = StyleSheet.create({
  cardWrapper: {
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: ZIndex.MODAL,
    ...Responsive.lessThanSm({
      top: 48,
    }),
  },
  cancel: {
    flex: 1,
    textAlign: 'center',
    marginTop: 20,
    marginRight: 20,
    fontSize: 16,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
      color: Colors.rootOrange(),
    },
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'horizontal',
  },
  headingText: {
    ...semiBold(),
    fontSize: 18,
    lineHeight: '125%',
    color: Colors.nearBlack(),
  },
  outerContent: {
    backgroundColor: Colors.white(),
    maxHeight: '95%',
    maxWidth: 900,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '35px 30px 35px',
    borderRadius: 4,
    ...Responsive.lessThanSm({
      height: '100%',
    }),
    ...Responsive.lessThanLg({
      width: '75%',
    }),
    ...Shadows.cardShadow(),
  },
});

PartnerModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  shouldShow: PropTypes.bool.isRequired,
};
