import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useMemo, useState } from '@root/vendor/react';
import Table, { FILTER_TYPES } from '@root/internal-dashboards-core/src/components/table';
import infoIcon from '@root/partners.admin.joinroot.com/src/assets/icons/info-icon.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function UsersTable({
  users,
  usersFilters,
  checkboxes = true,
  onRowSelect,
}) {
  const [filters, setFilters] = useState([]);

  const data = useMemo(() => {
    return users.map((user) => ({
      userId: user.id,
      name: user.partner.name,
      email: user.email,
      partnerId: user.partner.id,
      partnerTypeId: user.partner.partnerTypeId,
      type: user.partner.type,
    }));
  }, [users]);

  useEffect(() => {
    setFilters(usersFilters
      .filter((x) => x.value?.length)
      .map((filter) => {
        return {
          ...filter,
          type: FILTER_TYPES.INCLUDES_SOME,
        };
      }));
  }, [usersFilters]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'User Id',
        accessor: 'userId',

      }, {
        Header: 'Partner Name',
        accessor: 'name',
      }, {
        Header: 'Email',
        accessor: 'email',
      }, {
        Header: 'Partner Id',
        accessor: 'partnerId',
      }, {
        Header: 'Partner Type Id',
        accessor: 'partnerTypeId',
      }, {
        Header: 'Partner Type',
        accessor: 'type',
      },
    ];
  }, []);

  const hiddenColumns = [
    'partnerId',
    'partnerTypeId',
    'userId',
  ];
  return (
    <div css={styles.container}>
      {users.length !== 0 && (
        <Table
          columns={columns}
          data={data}
          filters={filters}
          initialState={{
            hiddenColumns,
          }}
          onRowSelect={onRowSelect}
          selectable={checkboxes}
          styleOverrides={tableStyles}
          useVirtualRows={true}
        />
      )
      }

      {users.length === 0 && (
        <div css={styles.noUsers}>
          <img
            alt={'info icon'}
            css={styles.infoIcon}
            src={infoIcon}
          />
          0 results found based on your filter criteria
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  noPartners: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: Colors.white(),
  },
  infoIcon: {
    paddingRight: 15,
    height: 25,
  },
});
const tableStyles = StyleSheet.create({
  tableHeaderGroup: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});
UsersTable.propTypes = {
  checkboxes: PropTypes.bool,
  onRowSelect: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    partner: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  })),
  usersFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
  })),
};

export default React.memo(UsersTable);
