import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useMemo, useState } from '@root/vendor/react';
import Table, { FILTER_TYPES } from '@root/internal-dashboards-core/src/components/table';
import dayjs from '@root/vendor/dayjs';
import infoIcon from '@root/partners.admin.joinroot.com/src/assets/icons/info-icon.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { EventTypes, costDisplayValue } from '@root/partners-common/src/models/partner-payment-event';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';

const effectiveDateSortType = (rowA, rowB) => rowA.original.effectiveDateRaw < rowB.original.effectiveDateRaw ? -1 : 1;
const expirationDateSortType = (rowA, rowB) => {
  const DATE_IN_DISTANT_PAST = '2000-01-01';
  const dateA = rowA.original.expirationDateRaw || DATE_IN_DISTANT_PAST;
  const dateB = rowB.original.expirationDateRaw || DATE_IN_DISTANT_PAST;
  return dateA < dateB ? -1 : 1;
};

function PricingRulesTable({
  pricingRuleFilters, pricingRules, onRowSelect, checkboxes = true, showEffective = false, showExpiration = false,
}) {
  const [filters, setFilters] = useState([]);

  const data = useMemo(() => {
    return pricingRules.map((pricingRule) => {
      return {
        id: pricingRule.id,
        pricingType: PricingTypes.MAPPING[pricingRule.pricingType],
        market: Market.MAPPING[pricingRule.market],
        marketId: pricingRule.market,
        partnerName: pricingRule.partner.name,
        partnerType: pricingRule.partner.partnerType.name,
        effectiveDateRaw: pricingRule.effectiveDate,
        effectiveDate: pricingRule.effectiveDate ? dayjs(pricingRule.effectiveDate).format('MMM D, YYYY') : '--',
        expirationDate: pricingRule.expirationDate ? dayjs(pricingRule.expirationDate).format('MMM D, YYYY') : '--',
        expirationDateRaw: pricingRule.expirationDate,
        firstEventType: EventTypes.displayValue(pricingRule.events[0]),
        firstEventCost: costDisplayValue(pricingRule.events[0]),
        secondEventType: EventTypes.displayValue(pricingRule.events[1]),
        secondEventCost: costDisplayValue(pricingRule.events[1]),
        partnerId: pricingRule.partner.id,
        partnerTypeId: pricingRule.partner.partnerType.id,
      };
    });
  }, [pricingRules]);

  useEffect(() => {
    setFilters(pricingRuleFilters
      .filter((x) => x.value?.length)
      .map((filter) => {
        return {
          ...filter,
          type: FILTER_TYPES.INCLUDES_SOME,
        };
      }));
  }, [pricingRuleFilters]);

  const columns = useMemo(() => {
    const columnsNames = [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Partner Type',
        accessor: 'partnerType',
      }, {
        Header: 'Partner',
        accessor: 'partnerName',
      }, {
        Header: 'Partner Id',
        accessor: 'partnerId',
      }, {
        Header: 'Effective',
        accessor: 'effectiveDate',
        sortType: effectiveDateSortType,
      }, {
        Header: 'Expires',
        accessor: 'expirationDate',
        sortType: expirationDateSortType,
      }, {
        Header: 'Market',
        accessor: 'market',
      }, {
        Header: 'Market Id',
        accessor: 'marketId',
      }, {
        Header: 'Pricing Type',
        accessor: 'pricingType',
      }, {
        Header: 'Event 1',
        accessor: 'firstEventType',
      }, {
        Header: 'Cost Per Event 1',
        accessor: 'firstEventCost',
      }, {
        Header: 'Event 2',
        accessor: 'secondEventType',
      }, {
        Header: 'Cost Per Event 2',
        accessor: 'secondEventCost',
      }];

    return columnsNames;
  }, []);

  const hiddenColumns = [
    'id',
    'marketId',
    'partnerId',
    'effectiveDateRaw',
    'expirationDateRaw',
  ];

  if (!showEffective) {
    hiddenColumns.push('effectiveDate');
  }

  if (!showExpiration) {
    hiddenColumns.push('expirationDate');
  }

  return (
    <div css={styles.container}>
      {pricingRules.length !== 0 && (
        <Table
          columns={columns}
          data={data}
          filters={filters}
          initialState={{
            hiddenColumns,
          }}
          onRowSelect={onRowSelect}
          selectable={checkboxes}
          styleOverrides={tableStyles}
          useVirtualRows={true}
        />
      )
      }

      {pricingRules.length === 0 && (
        <div css={styles.noPricingRule}>
          <img
            alt={'info icon'}
            css={styles.infoIcon}
            src={infoIcon}
          />
          0 results found based on your filter criteria
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  noPricingRule: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: Colors.white(),
  },
  infoIcon: {
    paddingRight: 15,
    height: 25,
  },
});
const tableStyles = StyleSheet.create({
  tableHeaderGroup: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

PricingRulesTable.propTypes = {
  checkboxes: PropTypes.bool,
  onRowSelect: PropTypes.func.isRequired,
  pricingRuleFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
  })),
  pricingRules: PropTypes.array,
  showEffective: PropTypes.bool,
  showExpiration: PropTypes.bool,
};

export default React.memo(PricingRulesTable);
