import { useState } from '@root/vendor/react';

export default function useShowModal() {
  const [shouldShowModal, setShowModal] = useState(false);

  const hide = () => {
    setShowModal(false);
  };

  const show = () => {
    setShowModal(true);
  };

  return [shouldShowModal, show, hide];
}
