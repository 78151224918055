import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function adjustPartnerEarningsConfiguration({ partnerAttributedEarningIds = [], earningAdjustmentAmount = 0 } = {}) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'earnings/adjust_earnings',
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.PUT,
    bodyParameters: {
      partnerAttributedEarningIds,
      earningAdjustmentAmount,
    },
  });
}
