import Market from '@root/core/src/models/market';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useRef } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import ZIndex from '@root/core/src/utils/z-index';
import dayjs from '@root/vendor/dayjs';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useModal from '@root/core/src/hooks/use-modal';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { EventTypes, costDisplayValue } from '@root/partners-common/src/models/partner-payment-event';
import { PricingTypes } from '@root/partners-common/src/models/pricing-rule';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function CancelPendingRuleConfirmationModal({
  onCancel, onConfirm, shouldShow, pricingRules,
}) {
  const { trackClick } = useAnalytics('PENDING_RULE_CANCEL', false);
  const portalRef = useRef();

  const renderPortal = usePortal('cancel-pending-rule-confirmation');
  useModal(onCancel, portalRef);

  const handleConfirm = () => {
    trackClick('CONFIRM');
    onConfirm();
  };

  const handleCancel = () => {
    trackClick('CANCEL');
    onCancel();
  };

  return renderPortal(
    <>
      {shouldShow &&
        <div css={styles.cardWrapper}>
          <div
            css={styles.outerContent}
            ref={portalRef}
          >
            <div css={styles.content}>
              <div css={styles.heading}>
                <div css={styles.headingText}>
                  Cancel Pending Rules
                </div>
              </div>
              <div css={styles.message}>
                <span css={styles.caution}>Caution. </span>
                You are about to cancel a rule that has not taken effect.
              </div>
              <div css={styles.message}>
                The following pending rule(s) will be canceled and deleted
              </div>
            </div>
            <div css={styles.tableContainer}>
              <table css={styles.table}>
                <thead css={styles.tableHead}>
                  <tr>
                    <th>Partner Type</th>
                    <th>Partner</th>
                    <th>Market</th>
                    <th>Pricing Type</th>
                    <th>Event 1</th>
                    <th>Event 1 Cost</th>
                    <th>Event 2</th>
                    <th>Event 2 Cost</th>
                    <th>Effective</th>
                  </tr>
                </thead>
                <tbody>
                  {pricingRules.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr >
                        <td>{r.partner.partnerType.name}</td>
                        <td>{r.partner.name}</td>
                        <td>{Market.MAPPING[r.market]}</td>
                        <td>{PricingTypes.MAPPING[r.pricingType]}</td>
                        <td>{EventTypes.displayValue(r.events[0])}</td>
                        <td>{costDisplayValue(r.events[0])}</td>
                        <td>{EventTypes.displayValue(r.events[1])}</td>
                        <td>{costDisplayValue(r.events[1])}</td>
                        <td>{dayjs(r.effectiveDate).format('MMM D, YYYY')}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div css={styles.buttonArea}>
              <div css={styles.buttons}>
                <div
                  css={styles.cancel}
                  onClick={handleCancel}
                >
                  Cancel
                </div>
                <div css={styles.applyWrapper}>
                  <PrimaryButton
                    css={styles.button}
                    onClick={handleConfirm}
                  >{'Confirm'}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

const styles = StyleSheet.create({
  table: {
    borderCollapse: 'collapse',
    color: Colors.gray50(),
    fontSize: 13.5,
    width: '95%',
    margin: '0 auto',
    th: {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 11,
      border: `1px solid ${Colors.gray30()}`,
      borderLeft: '0px none',
      textAlign: 'left',
      fontWeight: 'normal',
      '&:first-of-type': {
        borderLeft: `1px solid ${Colors.gray30()}`,
      },
    },
    td: {
      paddingLeft: 11,
      borderBottom: `1px solid ${Colors.gray30()}`,
      paddingTop: 8,
      paddingBottom: 8,
      '&:first-of-type': {
        borderLeft: `1px solid ${Colors.gray30()}`,
      },
      '&:last-child': {
        borderRight: `1px solid ${Colors.gray30()}`,
      },
    },
  },
  tableHead: {
    backgroundColor: Colors.nearWhite(),
  },
  cardWrapper: {
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: ZIndex.MODAL,
    ...Responsive.lessThanSm({
      top: 48,
    }),
  },
  outerContent: {
    backgroundColor: Colors.white(),
    maxHeight: '90%',
    maxWidth: 900,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: 35,
    paddingBottom: 35,
    padding: '35px 30px 35px',
    borderRadius: 4,
    ...Responsive.lessThanSm({
      height: '100%',
    }),
    ...Responsive.lessThanLg({
      width: '75%',
    }),
    ...Shadows.cardShadow(),
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'horizontal',
    marginBottom: 20,
  },
  headingText: {
    ...semiBold(),
    fontSize: 18,
    lineHeight: '125%',
    color: Colors.nearBlack(),
  },
  message: {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '125%',
    color: Colors.gray50(),
  },
  caution: {
    ...semiBold(),
    color: Colors.rootOrange(),
  },
  tableContainer: {
    width: '100%',
    overflowY: 'scroll',
    height: '80%',
  },
  tableBody: {
    maxHeight: '25vh',
    overflowY: 'scroll',
    display: 'block',
    '&::-webkit-scrollbar': {
      width: 15,
    },
    '&::-webkit-scrollbar-thumb': {
      border: '5px solid rgba(0, 0, 0, 0)',
      backgroundColor: '#E5E5EC',
      backgroundClip: 'padding-box',
      borderRadius: 15,
      width: 10,
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
  },
  tableRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid ${Colors.gray10()}`,
  },
  headingRow: {
    padding: 10,
    width: '100%',
    display: 'flex',
    borderRight: `1px solid ${Colors.gray10()}`,
    backgroundColor: Colors.nearWhite(),
    alignItems: 'center',
    justifyContent: 'center',
    ...Theme.paragraph2(),
  },
  tableHeader: {
    maxHeight: '25vh',
    overflowY: 'scroll',
    display: 'block',
    '&::-webkit-scrollbar': {
      width: 15,
      borderTop: `1px solid ${Colors.gray10()}`,
    },
  },
  tableDetail: {
    padding: 10,
    width: '100%',
    borderRight: `1px solid ${Colors.gray10()}`,
    ...Theme.paragraph2(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonArea: {
    justifyContent: 'center',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
  },
  button: {
    position: 'relative',
    width: 150,
    zIndex: 200,
    marginTop: 20,
  },
  cancel: {
    flex: 1,
    textAlign: 'center',
    marginTop: 20,
    fontSize: 16,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
      color: Colors.rootOrange(),
    },
  },
  applyWrapper: {
    ...Shadows.normal(),
  },
});

CancelPendingRuleConfirmationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  pricingRules: PropTypes.array.isRequired,
  shouldShow: PropTypes.bool.isRequired,
};
