import CheckCircleFilledDarkSeafoam from '@root/core/src/assets/check-circle-filled-dark-seafoam';
import Input from '@root/core/src/components/input';
import KeyCode from '@root/core/src/utils/keycode';
import MenuSearch from '@root/core/src/assets/menu-search';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function FilterList({
  errorLabel,
  focusOnMount = false,
  inputLabel,
  inputStyle,
  inputValue,
  onChange,
  options,
}) {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [focusedOption, setFocusedOption] = useState({
    value: null,
    label: null,
  });

  const optionRefs = filteredOptions.map(React.createRef);

  const getOptionByLabel = (label) => {
    const option = options.find((op) => op.label.toLowerCase() === label.toLowerCase());

    return option || {
      label,
      value: null,
    };
  };

  const handleChange = (label) => {
    filterOptions(label);
    onChange(getOptionByLabel(label));
  };

  const selectOption = (option) => {
    filterOptions(option.label);
    onChange(option);
  };

  const filterOptions = (label) => {
    setFocusedOption({
      value: null,
      label: null,
    });
    setFilteredOptions(options.filter((option) => option.label.toLowerCase().startsWith(label.toLowerCase())));
  };

  const validOptionIsSelected = filteredOptions.length === 1 && options.some((option) => option.label.toLowerCase() === inputValue.toLowerCase());

  const errorLabelText = !filteredOptions.length && !validOptionIsSelected ? errorLabel : null;

  const scrollToRef = (ref) => ref.current.scrollIntoView({
    block: 'nearest',
  });

  const getOptionRef = (option = {}) => {
    const optionIndex = filteredOptions.findIndex((o) => option.value === o.value);
    return optionRefs[optionIndex];
  };

  const focusNextOption = () => {
    let optionToFocus;

    if (!focusedOption.value) {
      optionToFocus = filteredOptions[0];
    } else {
      const optionToFocusIdx = filteredOptions.indexOf(focusedOption) + 1;

      if (optionToFocusIdx < filteredOptions.length) {
        optionToFocus = filteredOptions[optionToFocusIdx];
      }
    }

    if (optionToFocus) {
      setFocusedOption(optionToFocus);
      scrollToRef(getOptionRef(optionToFocus));
    }
  };

  const focusPreviousOption = () => {
    let optionToFocus;

    if (focusedOption.value) {
      const optionToFocusIdx = filteredOptions.indexOf(focusedOption) - 1;

      if (optionToFocusIdx >= 0) {
        optionToFocus = filteredOptions[optionToFocusIdx];
      }
    }

    if (optionToFocus) {
      setFocusedOption(optionToFocus);
      scrollToRef(getOptionRef(optionToFocus));
    }
  };

  const handleKeypress = (event) => {
    const keyCode = KeyCode.fromEvent(event);

    if (keyCode === KeyCode.KEYCODES.DOWN) {
      event.stopPropagation();
      event.preventDefault();
      focusNextOption();
    } else if (keyCode === KeyCode.KEYCODES.UP) {
      event.stopPropagation();
      event.preventDefault();
      focusPreviousOption();
    } else if (keyCode === KeyCode.KEYCODES.ENTER) {
      focusedOption.value && selectOption(focusedOption);
    }
  };

  const inputIcon = validOptionIsSelected ? <CheckCircleFilledDarkSeafoam /> : <MenuSearch />;

  const containerStyles = [styles.optionContainer,
    filteredOptions.length && !validOptionIsSelected ? '' : styles.containerHidden,
  ];

  const inputStyles = validOptionIsSelected ? styles.optionSelectedInput : null;

  const optionStyles = (option) => [styles.option, option === focusedOption ? styles.optionHovered : null];

  return (
    <div
      onKeyDown={handleKeypress}
    >
      <Input
        autoComplete={'off'}
        css={inputStyles}
        errorLabel={errorLabelText}
        focusOnMount={focusOnMount}
        icon={inputIcon}
        inputStyle={inputStyle}
        label={inputLabel}
        onChange={handleChange}
        value={inputValue}
      />
      <div
        css={containerStyles}
      >
        <div css={styles.innerContainer} >
          {filteredOptions.map((option, index) => {
            return (
              <div
                css={optionStyles(option)}
                key={option.value}
                onClick={() => selectOption(option)}
                ref={optionRefs[index]}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  optionSelectedInput: {
    background: Colors.DEPRECATED_lightSeafoam(),
  },
  optionContainer: {
    ...Theme.roundedCorners(),
    marginTop: 15,
    height: 225,
  },
  containerHidden: {
    visibility: 'hidden',
  },
  innerContainer: {
    border: `1px solid ${Colors.DEPRECATED_bonJour()}`,
    ...Theme.roundedCorners(),
    overflowY: 'auto',
    maxHeight: 225,
  },
  option: {
    cursor: 'pointer',
    ...Theme.paragraph1(),
    color: Colors.nearBlack(),
    padding: '12px 14px',
    borderBottom: `1px solid ${Colors.DEPRECATED_bonJour()}`,
    background: Colors.nearWhite(),
    ':first-of-type': {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    ':last-child': {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      borderBottom: 0,
    },
    ':hover': {
      background: Colors.gray20(),
    },
  },
  optionHovered: {
    background: Colors.gray20(),
  },
});

FilterList.propTypes = {
  errorLabel: PropTypes.string.isRequired,
  focusOnMount: PropTypes.bool,
  inputLabel: PropTypes.string.isRequired,
  inputStyle: PropTypes.object,
  inputValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};
