import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function getUsersConfiguration(includeInactive = false) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'users',
    queryParameters: {
      includeInactive,
    },
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.GET,
  });
}
