import PartnerProgramHeader from '@root/partners.admin.joinroot.com/src/components/partner-program-header';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function PartnersAdminWrapper({ children }) {
  return (
    <>
      <PartnerProgramHeader />
      <div css={styles.container}>
        {children}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    background: Colors.nearWhite(),
    marginTop: 63,
  },
});

PartnersAdminWrapper.propTypes = {
  activeScene: PropTypes.string,
  children: PropTypes.node.isRequired,
  containerStyle: PropTypes.object,
};
