import PartnersAndMarketsContext from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import usePartnerAndMarketData from '@root/partners.admin.joinroot.com/src/hooks/use-partner-and-market-data';
import { useOktaAuth } from '@root/vendor/@okta/okta-react';

const PartnersAndMarketsContextProvider = ({ children }) => {
  const { authState } = useOktaAuth();

  const partnersAndMarketData = usePartnerAndMarketData(authState?.isAuthenticated);

  return (
    <PartnersAndMarketsContext.Provider value={partnersAndMarketData}>
      {children}
    </PartnersAndMarketsContext.Provider>
  );
};

PartnersAndMarketsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PartnersAndMarketsContextProvider;
