import Datepicker from '@root/core/src/components/datepicker';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import dayjs from '@root/vendor/dayjs';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { dayPickerInputStyles, dayPickerStyles } from '@root/partners.admin.joinroot.com/src/utils/partner-admin-datepicker-styles';

const firstDayOfMonth = (day = new Date()) => {
  return new Date(day.getFullYear(), day.getMonth(), 1);
};

export default function PartnerDeactivate({ onApply }) {
  const { trackClick } = useAnalytics('PARTNER_DEACTIVATE', false);

  const [effectiveDate, setEffectiveDate] = useState(null);

  const handleApply = () => {
    trackClick('APPLY', { effectiveDate });
    onApply(effectiveDate);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setEffectiveDate(formattedDate);
  };

  return (
    <div>
      <div css={styles.item}>
        <label
          css={styles.selectLabel}
          htmlFor={'effective-date'}
        >
          Effective Date
        </label>
      </div>
      <div css={styles.item}>
        <Datepicker
          disablePastDays
          inputId={'effective-date'}
          inputStyles={dayPickerInputStyles}
          label={''}
          onDateChange={handleDateChange}
          pickerStyles={dayPickerStyles}
          today={firstDayOfMonth()}
        />
      </div>

      <div css={styles.item}>
        <PrimaryButton
          css={styles.button}
          disabled={!effectiveDate}
          onClick={handleApply}
        >
          Deactivate Partner(s)
        </PrimaryButton>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  item: {
    display: 'inline-flex',
    '& + &': {
      marginLeft: 10,
    },
  },
  selectLabel: {
    color: Colors.gray50(),
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 3,
    marginBottom: 0,
    cursor: 'pointer',
  },
  button: {
    paddingLeft: 15,
    paddingRight: 15,
  },
});

PartnerDeactivate.propTypes = {
  onApply: PropTypes.func.isRequired,
};
