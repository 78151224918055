import PropTypes from '@root/vendor/prop-types';
import React, { useRef } from '@root/vendor/react';
import Shadows from '@root/brand/src/utils/shadows';
import ZIndex from '@root/core/src/utils/z-index';
import useModal from '@root/core/src/hooks/use-modal';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function Modal({
  children, id, onClose,
}) {
  const renderPortal = usePortal(id);

  const contentRef = useRef();

  useModal(onClose, contentRef);

  return renderPortal(
    <div css={styles.overlay}>
      <div
        css={styles.content}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  overlay: {
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: ZIndex.MODAL,
  },
  content: {
    backgroundColor: Colors.white(),
    maxHeight: '90%',
    padding: '35px 30px 35px',
    borderRadius: 4,
    ...Shadows.cardShadow(),
  },
});
