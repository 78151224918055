import getPendingPricingRulesConfiguration from '@root/partners.admin.joinroot.com/src/api/get-pricing-rules/get-pending-pricing-rules-configuration.js';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useCallback, useEffect, useState } from '@root/vendor/react';

export default function usePendingPricingRules(combinations) {
  const [pendingPricingRules, setPendingPricingRules] = useState([]);
  const [request, isLoading] = useImperativeNetworkRequest(getPendingPricingRulesConfiguration);

  const refresh = useCallback(async () => {
    const result = await request(combinations || []);
    if (result.isSuccess()) {
      result.data.pricingRules.sort((a, b) => a.id > b.id ? 1 : -1);
      setPendingPricingRules(result.data.pricingRules);
    }
  }, [combinations, request]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [pendingPricingRules, isLoading, refresh];
}
