import CaretLoader from '@root/core/src/components/caret-loader';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useMemo, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import dayjs from '@root/vendor/dayjs';
import getPaperTrailConfiguration from '@root/partners.admin.joinroot.com/src/api/get-paper-trail/get-paper-trail-configuration';
import infoIcon from '@root/partners.admin.joinroot.com/src/assets/icons/info-icon.svg';
import relativeTime from '@root/vendor/dayjs/plugin/relativeTime';
import startCase from '@root/vendor/lodash/startCase';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

dayjs.extend(relativeTime);

const activityEventTypeDisplayValues = {
  update: 'updated',
};

export default function TriageRuleActivityList({ triageRuleId }) {
  const [triageRulePaperTrail, setTriageRulePaperTrail] = useState(null);
  const configuration = useMemo(() => getPaperTrailConfiguration({
    className: 'Partners::TriageRule',
    id: triageRuleId,
  }), [triageRuleId]);

  const isLoading = useNetworkRequest(configuration, useCallback((result) => {
    setTriageRulePaperTrail(result.data);
  }, []));

  if (isLoading) {
    return (
      <CaretLoader />
    );
  }

  return (
    <div css={styles.container}>
      <div css={styles.activityHeader}>ACTIVITY</div>
      {triageRulePaperTrail.length === 0 && (
        <div css={styles.noActivity}>
          <img
            alt={'info icon'}
            css={styles.infoIcon}
            src={infoIcon}
          />
          No activity found for this triage rule
        </div>
      )
      }
      {triageRulePaperTrail.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1).map((activityItem, i) => {
        return (
          <div
            css={styles.activityItem}
            key={i}
          >
            <div css={styles.section}>
              <div
                className={'activity-circle'}
                css={styles.activityCircle}
              >
                <span />
              </div>
              <div css={styles.timestamp}>
                {dayjs(activityItem.createdAt).fromNow()}
              </div>
              <div
                style={{
                  display: 'inline-block',
                }}
              >
                <span css={styles.user}>{activityItem.whodunnit.replace('@joinroot.com', '')}</span>
              </div>
            </div>
            <div css={styles.section}>
              <span css={styles.action}>{activityEventTypeDisplayValues[activityItem.event]}</span>
              <span
                css={styles.item}
                title={`${startCase(activityItem.objectChanges.productType[0])} -> ${startCase(activityItem.objectChanges.productType[1])}`}
              >
                Product
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

const styles = StyleSheet.create({
  section: {
    marginTop: 8,
    '&:last-of-type': {
      paddingLeft: 19,
      ...Responsive.xl({
        paddingLeft: 0,
      }),
    },
  },
  activityCircle: {
    display: 'inline-block',
    width: 11,
    height: 11,
    border: `1px solid ${Colors.rootOrange()}`,
    borderRadius: '50%',
  },
  activityHeader: {
    color: Colors.gray50(),
    marginBottom: 15,
  },
  activityItem: {
    minHeight: 29,
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    ...Responsive.xl({
      flexWrap: 'nowrap',
    }),
    '& + div .activity-circle span': {
      position: 'relative',
      '&::before': {
        background: '#FFB599',
        content: '\'\'',
        width: 1,
        height: 42,
        top: -42,
        position: 'absolute',
        left: 4,
        ...Responsive.xl({
          height: 19,
          top: -18,
        }),
      },
    },
  },
  noActivity: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    paddingRight: 10,
    height: 20,
  },
  container: {
    ...Theme.paragraph2(),
  },
  timestamp: {
    display: 'inline-block',
    color: Colors.black(),
    paddingLeft: 15,
  },
  user: {
    color: '#008DD6',
    marginLeft: 10,
  },
  action: {
    ...Theme.paragraph2({
      italic: true,
    }),
    color: Colors.gray50(),
    marginLeft: 7,

  },
  item: {
    color: Colors.rootOrange(),
    marginLeft: 7,
  },
});

TriageRuleActivityList.propTypes = {
  triageRuleId: PropTypes.string.isRequired,
};
