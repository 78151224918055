import MultiSelectOption from '@root/internal-dashboards-core/src/components/multi-select-option';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function MultiSelectList({
  title,
  listValues,
  onItemClick,
  onSelectAllClick,
  selectAllLabel = 'Select All',
  selectedItems,
}) {
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  useEffect(() => {
    selectedItems && selectedItems.length > 0 && selectedItems.length === listValues.length ? setAllItemsSelected(true) : setAllItemsSelected(false);
  }, [listValues, selectedItems]);

  const handleItemClick = (item) => {
    onItemClick(item);
  };

  const handleSelectAllClick = () => {
    if (allItemsSelected) {
      onSelectAllClick(false);
    } else {
      onSelectAllClick(true);
    }
  };

  const isIndividualItemSelected = (item) => {
    return selectedItems.includes(item);
  };

  return (
    <div css={styles.formContainer}>
      <div css={styles.topSection}>
        <div css={styles.checkbox}>
          <MultiSelectOption
            onClick={() => handleSelectAllClick()}
            selected={allItemsSelected}
            value={'select-all-checkbox'}
          />
          <label
            css={styles.selectAll}
          >
            {selectAllLabel} {title}
          </label>
        </div>
      </div>
      <div css={styles.optionsContainer}>
        {
          listValues
            .sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)
            .map((item) => (
              <div
                css={styles.itemsContainer}
                key={item.id}
              >
                <MultiSelectOption
                  onClick={() => handleItemClick(item.id)}
                  selected={isIndividualItemSelected(item.id)}
                  value={item.name}
                />
                <label
                  css={isIndividualItemSelected(item.id) ? styles.selectedLabel : styles.label}
                  data-testid={'item-name-label'}
                  onClick={() => handleItemClick(item.id)}
                >
                  {item.name}
                </label>
              </div>
            ))
        }
      </div>
    </div>
  );
}

const labelStyling = {
  paddingLeft: 15,
  lineHeight: 1.5,
  cursor: 'pointer',
};

const simulateBold = {
  textShadow: 'rgb(0, 0, 0) 0.5px 0px',
};

const styles = StyleSheet.create({
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...Theme.paragraph2(),
  },
  topSection: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 21,
    paddingTop: 8,
    width: '100%',
    background: Colors.white(),
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
  },
  selectAll: {
    fontWeight: 'bold',
    paddingLeft: 15,
    lineHeight: 1.5,
  },
  optionsContainer: {
    background: Colors.nearWhite(),
    width: '100%',
    maxHeight: '25vh',
    padding: 5,
    overflowY: 'scroll',
    borderTop: `1px solid ${Colors.gray10()}`,
    borderBottom: `1px solid ${Colors.gray10()}`,
    '&::-webkit-scrollbar': {
      width: 20,
    },
    '&::-webkit-scrollbar-thumb': {
      border: '7px solid rgba(0, 0, 0, 0)',
      backgroundColor: '#E5E5EC',
      backgroundClip: 'padding-box',
      borderRadius: 15,
      width: 10,
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 16,
  },
  selectedLabel: {
    ...labelStyling,
    ...simulateBold,
    color: Colors.black(),
  },
  label: labelStyling,
});

MultiSelectList.propTypes = {
  isLoading: PropTypes.bool,
  listValues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  })),
  onItemClick: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  selectAllLabel: PropTypes.string,
  selectedItems: PropTypes.array,
  title: PropTypes.string.isRequired,
};
