export default class ProductTypes {
  static Types = {
    CUSTOMER_CHOICE: 'customer_choice',
    DAY_ZERO: 'day_zero',
    CLASSIC: 'classic',
    DEFAULT: 'default',
  };

  static Titles = {
    CUSTOMER_CHOICE: 'Customer Choice',
    DAY_ZERO: 'Day Zero',
    CLASSIC: 'Classic',
    DEFAULT: 'Default',
  };
}
