import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function getPaperTrailConfiguration({ className, id }) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'paper_trails',
    queryParameters: {
      className,
      id,
    },
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.GET,
  });
}
