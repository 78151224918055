import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function getPendingPricingRulesConfiguration(partnerMarketCombinations = []) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'pricing_rules/pending_rules',
    bodyParameters: {
      partnerMarketCombinations,
    },
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.POST,
  });
}
