import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function getTriageRulesConfiguration({
  markets = [], partnerIds = [], partnerTypeIds = [],
} = {}) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'triage_rules',
    queryParameters: {
      markets,
      partnerIds,
      partnerTypeIds,
    },
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.GET,
  });
}
