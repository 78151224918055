import DashboardLayout from '@root/partners.admin.joinroot.com/src/components/dashboard-layout';
import HeaderLayout from '@root/partners.admin.joinroot.com/src/components/header-layout';
import Loading from '@root/partners.admin.joinroot.com/src/components/loading';
import PartnerAdminWrapper from '@root/partners.admin.joinroot.com/src/components/partners-admin-wrapper';
import PartnersAndMarketsContext from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context';
import PricingRulesTable from '@root/partners.admin.joinroot.com/src/components/pricing-rules-table';
import React, { useContext, useState } from '@root/vendor/react';
import RuleFilters from '@root/partners.admin.joinroot.com/src/components/rule-filters';
import usePricingRules from '@root/partners.admin.joinroot.com/src/hooks/use-pricing-rules';

export default function PricingHistoricalRules() {
  const {
    supportedMarkets, partners, partnerTypes, networkCallsPending,
  } = useContext(PartnersAndMarketsContext);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [historicPricingRules, isLoadingHistoricPricingRules] = usePricingRules({
    includeExpired: true,
  });

  const displayLoader = isLoadingHistoricPricingRules || networkCallsPending;

  return (
    <PartnerAdminWrapper>
      <HeaderLayout title={'Historic Summary'} />
      {
        !displayLoader && (
          <DashboardLayout
            filters={
              <RuleFilters
                markets={supportedMarkets}
                onChange={setSelectedFilters}
                partners={partners}
                partnerTypes={partnerTypes}
              />
            }
            table={
              <PricingRulesTable
                checkboxes={false}
                onRowSelect={() => {}}
                pricingRuleFilters={selectedFilters}
                pricingRules={historicPricingRules}
                showEffective={true}
                showExpiration={true}
              />
            }
          />
        )}
      {displayLoader && <Loading />}
    </PartnerAdminWrapper>
  );
}
