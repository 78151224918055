import PartnerAdminApiNetworkRequestConfiguration from '@root/partners.admin.joinroot.com/src/api/partner-admin-api-network-request-configuration';

export default function addUserConfiguration({ email, partnerId }) {
  return new PartnerAdminApiNetworkRequestConfiguration({
    endpoint: 'users',
    bodyParameters: {
      email,
      partnerId,
    },
    method: PartnerAdminApiNetworkRequestConfiguration.Methods.POST,
  });
}
