import getClassifiersConfiguration from '@root/partners.admin.joinroot.com/src/api/get-classifiers/get-classifiers-configuration';
import useSafeNetworkRequest from '@root/core/src/hooks/use-safe-network-request';
import { useCallback, useState } from '@root/vendor/react';

const configuration = getClassifiersConfiguration();

export default function useClassifiers() {
  const [classifiers, setClassifiers] = useState([]);

  const isLoading = useSafeNetworkRequest(configuration, useCallback((result) => {
    if (result.isSuccess()) {
      setClassifiers(result.data);
    }
  }, []));

  return [classifiers, isLoading];
}
