import DashboardLayout from '@root/partners.admin.joinroot.com/src/components/dashboard-layout';
import HeaderLayout from '@root/partners.admin.joinroot.com/src/components/header-layout';
import Loading from '@root/partners.admin.joinroot.com/src/components/loading';
import PartnerAdminWrapper from '@root/partners.admin.joinroot.com/src/components/partners-admin-wrapper';
import PartnerDeactivate from '@root/partners.admin.joinroot.com/src/components/partner-deactivate';
import PartnerDeactivationConfirmationModal from '@root/partners.admin.joinroot.com/src/components/partner-deactivation-confirmation-modal';
import PartnerModal from '@root/partners.admin.joinroot.com/src/components/partner-modal';
import PartnersAndMarketsContext from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context';
import PartnersTable from '@root/partners.admin.joinroot.com/src/components/partners-table';
import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import React, { useCallback, useContext, useState } from '@root/vendor/react';
import RuleFilters from '@root/partners.admin.joinroot.com/src/components/rule-filters';
import Toast, { TOAST_TYPES, errorToast, successToast } from '@root/partners.admin.joinroot.com/src/components/toast';
import deactivatePartnersConfiguration from '@root/partners.admin.joinroot.com/src/api/partners/deactivate-partners-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useShowModal from '@root/core/src/hooks/use-show-modal';
import { StyleSheet } from '@root/core/src/utils/styles';
import { createPartnerConfiguration, updatePartnerConfiguration } from '@root/partners.admin.joinroot.com/src/api/partner-configuration';

export default function PartnersWrapper() {
  return (
    <PartnerAdminWrapper>
      <HeaderLayout title={'PAYOUT PARTNERS'} />
      <Partners />
    </PartnerAdminWrapper>
  );
}

function Partners() {
  const {
    partners, partnerTypes, networkCallsPending, refresh,
  } = useContext(PartnersAndMarketsContext);
  const [deactivate, isDeactivating] = useImperativeNetworkRequest(deactivatePartnersConfiguration);

  const [createPartner, isCreating] = useImperativeNetworkRequest(createPartnerConfiguration);
  const [updatePartner, isUpdating] = useImperativeNetworkRequest(updatePartnerConfiguration);
  const [showNewPartnerModal, show, hide] = useShowModal();

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [deactivateEffectiveDate, setDeactivateEffectiveDate] = useState();
  const [toastMessage, setToastMessage] = useState();
  const [editPartner, setEditPartner] = useState();
  const [addPartnerClicked, setAddPartnerClicked] = useState(false);

  const handleRowSelect = useCallback((selectedRows) => {
    setSelectedPartners(
      selectedRows.map((row) => partners.find((partner) => partner.id === row.partnerId))
    );
  }, [partners]);

  const handleDeactivateCancel = () => {
    setDeactivateEffectiveDate(null);
  };

  const handleDeactivateConfirm = async () => {
    const result = await deactivate({
      partnerIds: selectedPartners.map((p) => p.id),
      endDate: deactivateEffectiveDate,
    });

    setDeactivateEffectiveDate(null);

    if (result.isSuccess()) {
      setToastMessage(successToast({ message: 'Partner(s) deactivated' }));
      refresh('partners');
    } else {
      setToastMessage(errorToast({ message: 'There was an error deactivating the partner(s)' }));
    }
  };

  const handleNewPartner = async (body) => {
    hide();

    const result = await createPartner(body);

    if (result.isSuccess()) {
      refresh('partners');
    }

    setToastMessage({
      message: result.isSuccess() ? 'Partner added successfully!' : 'There was an error adding partner.',
      title: result.isSuccess() ? 'Success' : 'Error',
      type: result.isSuccess() ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
    });
  };

  const handleUpdatePartner = async (body) => {
    hide();

    const result = await updatePartner(body);

    if (result.isSuccess()) {
      refresh('partners');
    }

    setEditPartner(null);

    setToastMessage({
      message: result.isSuccess() ? 'Partner updated successfully!' : 'There was an error updating partner.',
      title: result.isSuccess() ? 'Success' : 'Error',
      type: result.isSuccess() ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
    });
  };

  const handleAddPartnerClicked = () => {
    setAddPartnerClicked(true);
    show();
  };

  const handleEditPartnerClicked = () => {
    setEditPartner(selectedPartners[0]);
    setAddPartnerClicked(false);
    show();
  };

  const handleModalCancel = () => {
    hide();
    setEditPartner(null);
  };

  const renderTableHeader = () => {
    return (
      <div css={styles.tableHeader}>
        {selectedPartners.length > 0 && <PartnerDeactivate onApply={setDeactivateEffectiveDate} />}
        <div css={styles.buttonSection}>
          {selectedPartners.length > 0 &&
          <PrimaryButton
            css={styles.button}
            disabled={selectedPartners.length !== 1}
            onClick={handleEditPartnerClicked}
          >
            Edit Partner
          </PrimaryButton>
          }
          <PrimaryButton
            css={styles.button}
            onClick={handleAddPartnerClicked}
          >
            {'New Partner'}
          </PrimaryButton>
        </div>
      </div>);
  };

  if (networkCallsPending || isDeactivating || isCreating || isUpdating) {
    return <Loading />;
  }

  return (
    <>
      <DashboardLayout
        filters={
          <RuleFilters
            onChange={setSelectedFilters}
            partners={partners}
            partnerTypes={partnerTypes}
          />
        }
        table={
          <PartnersTable
            onRowSelect={handleRowSelect}
            partnerFilters={selectedFilters}
            partners={partners}
          />
        }
        tableHeader={renderTableHeader()}
      />
      {
        !!deactivateEffectiveDate &&
          <PartnerDeactivationConfirmationModal
            effectiveDate={deactivateEffectiveDate}
            onCancel={handleDeactivateCancel}
            onConfirm={handleDeactivateConfirm}
            partners={selectedPartners}
          />
      }

      <PartnerModal
        onCancel={handleModalCancel}
        onConfirm={addPartnerClicked ? handleNewPartner : handleUpdatePartner}
        partner={editPartner}
        shouldShow={showNewPartnerModal}
      />

      <Toast
        analyticsContext={'PARTNERS'}
        toastMessage={toastMessage}
      />
    </>
  );
}

const styles = StyleSheet.create({
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
  },

  selectLabel: {
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 3,
    marginBottom: 0,
    cursor: 'pointer',
  },
  tableHeader: {
    padding: '12px 24px',
    display: 'flex',
  },
  button: {
    width: 175,
    padding: 15,
    marginLeft: 10,
  },
});
