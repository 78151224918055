import AssignProduct from '@root/partners.admin.joinroot.com/src/components/assign-product';
import DashboardLayout from '@root/partners.admin.joinroot.com/src/components/dashboard-layout';
import HeaderLayout from '@root/partners.admin.joinroot.com/src/components/header-layout';
import Loading from '@root/partners.admin.joinroot.com/src/components/loading';
import PartnerAdminWrapper from '@root/partners.admin.joinroot.com/src/components/partners-admin-wrapper';
import PartnerSummaryPane from '@root/partners.admin.joinroot.com/src/components/partner-summary-pane';
import PartnersAndMarketsContext from '@root/partners.admin.joinroot.com/src/state/partners-and-markets-context';
import React, { useCallback, useContext, useState } from '@root/vendor/react';
import RuleFilters from '@root/partners.admin.joinroot.com/src/components/rule-filters';
import Toast, { TOAST_TYPES } from '@root/partners.admin.joinroot.com/src/components/toast';
import TriageRuleChangeConfirmationModal from '@root/partners.admin.joinroot.com/src/components/triage-rule-change-confirmation-modal';
import TriageRulesDisplay from '@root/partners.admin.joinroot.com/src/components/triage-rules-display';
import TriageRulesTable from '@root/partners.admin.joinroot.com/src/components/triage-rules-table';
import upsertTriageRulesConfiguration from '@root/partners.admin.joinroot.com/src/api/upsert-triage-rules/upsert-triage-rules-configuration';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useClassifiers from '@root/partners.admin.joinroot.com/src/hooks/use-classifiers';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useShowModal from '@root/core/src/hooks/use-show-modal';
import useTriageRules from '@root/partners.admin.joinroot.com/src/hooks/use-triage-rules';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function Triage() {
  const {
    hybridMarkets, customerChoiceMarkets, partners, partnerTypes, networkCallsPending,
  } = useContext(PartnersAndMarketsContext);

  const [activeTriageRule, setActiveTriageRule] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [classifiers, loadingClassifiers] = useClassifiers();
  const [showConfirmationModal, show, hide] = useShowModal();
  const [upsertTriageRulesRequest, isUpserting] = useImperativeNetworkRequest(upsertTriageRulesConfiguration);

  const [triageRules, refreshTriageRules, isLoadingTriageRules] = useTriageRules();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);

  const [selectedTriageRules, setSelectedTriageRules] = useState([]);

  const { trackClick, trackEvent } = useAnalytics('TRIAGE');

  const triageRuleFiltersChanged = useCallback((filters) => {
    setSelectedFilters(filters);
  }, [setSelectedFilters]);

  const handleRowSelect = useCallback((selectedRows) => {
    setSelectedTriageRules(selectedRows);
  }, [setSelectedTriageRules]);

  const handleAssignProductClick = (productType) => {
    if (productType === 'customer_choice') {
      const invalidMarkets = selectedTriageRules.filter((row) => !customerChoiceMarkets.includes(row.marketId));
      if (invalidMarkets.length > 0) {
        trackEvent('INVALID_CUSTOMER_CHOICE_MARKET_VIEWED');
        setToastMessage({
          message: `The following markets are not available for Customer Choice assignment: ${invalidMarkets.map((row) => ' ' + row.market)}.`,
          title: 'Error',
          type: TOAST_TYPES.ERROR,
        });
        return;
      }
    }
    trackClick('ASSIGN_PRODUCT_' + productType);
    setSelectedProduct(productType);
    show();
  };

  const handleSystemChangeConfirmation = async () => {
    hide();

    const result = await upsertTriageRulesRequest(selectedTriageRules.map((row) => {
      return {
        market: row.marketId,
        partnerId: row.partnerId,
        productType: selectedProduct,
      };
    }));

    if (result.isSuccess()) {
      await refreshTriageRules();
    }

    setToastMessage({
      message: result.isSuccess() ? 'Triage rules updated successfully' : 'There was an error updating triage rules',
      title: result.isSuccess() ? 'Success' : 'Error',
      type: result.isSuccess() ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
    });
  };

  const displayLoader = isLoadingTriageRules || networkCallsPending || isUpserting;

  return (
    <>
      <PartnerAdminWrapper>
        <HeaderLayout title={'Triage'} />
        <TriageRuleChangeConfirmationModal
          onCancel={() => hide()}
          onConfirm={handleSystemChangeConfirmation}
          shouldShow={showConfirmationModal}
        />
        {!displayLoader && (
          <DashboardLayout
            filters={
              <RuleFilters
                markets={hybridMarkets}
                onChange={triageRuleFiltersChanged}
                partners={partners}
                partnerTypes={partnerTypes}
              />
            }
            rightPanel={
              activeTriageRule &&
              <div css={styles.partnerSummaryPaneContainer}>
                <PartnerSummaryPane
                  onClose={() => setActiveTriageRule(null)}
                  triageRule={activeTriageRule}
                />
              </div>
            }
            table={
              <>
                <div css={styles.tableTop}>
                  <div>
                    <div css={styles.tableTopButton}>
                      {selectedTriageRules.length > 0 && <AssignProduct onClick={handleAssignProductClick} />}
                    </div>
                    <div css={styles.tableTopButton}>
                      <TriageRulesDisplay
                        isLoading={loadingClassifiers}
                        triageRules={classifiers}
                      />
                    </div>
                  </div>
                </div>

                <TriageRulesTable
                  activeTriageRuleId={activeTriageRule?.id}
                  onRowSelect={handleRowSelect}
                  onTriageRuleSelection={(triageRule) => setActiveTriageRule(triageRule)}
                  triageRuleFilters={selectedFilters}
                  triageRules={triageRules}
                />
              </>
            }
          />
        )}
        {displayLoader && <Loading />}

        <Toast
          analyticsContext={'TRIAGE'}
          toastMessage={toastMessage}
        />

      </PartnerAdminWrapper>
    </>
  );
}

const styles = StyleSheet.create({
  tableTop: {
    backgroundColor: Colors.nearWhite(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 8,
    border: `1px solid ${Colors.gray10()}`,
  },
  tableTopButton: {
    display: 'inline-block',
    marginRight: 8,
    ':last-of-type': {
      marginRight: 0,
    },
  },
  partnerSummaryPaneContainer: {
    width: '25%',
    maxWidth: 400,
    border: `1px solid ${Colors.gray10()}`,
    position: 'relative',
    paddingTop: 45,
    paddingLeft: 30,
    backgroundColor: Colors.white(),
  },
});
