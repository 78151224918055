import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useMemo, useState } from '@root/vendor/react';
import Table, { FILTER_TYPES } from '@root/internal-dashboards-core/src/components/table';
import dayjs from '@root/vendor/dayjs';
import infoIcon from '@root/partners.admin.joinroot.com/src/assets/icons/info-icon.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function PartnerEarningsTable({
  partnerEarnings,
  onRowSelect,
  partnerFilters,
  checkboxes = true,
}) {
  const [filters, setFilters] = useState([]);

  const data = useMemo(() => {
    return partnerEarnings.map((partnerEarning) => {
      return {
        partnerEarningId: partnerEarning.id,
        partnerId: partnerEarning.partnerId,
        partnerName: partnerEarning.partnerName,
        partnerTypeId: partnerEarning.partnerTypeId,
        partnerType: partnerEarning.partnerType,
        tier: partnerEarning.tier,
        earnedMonth: partnerEarning.earnedMonth,
        profileCount: partnerEarning.profileCount,
        attributedEarnedEventCount: partnerEarning.attributedEarnedEventCount,
        testDriveCount: partnerEarning.testDriveCount,
        totalEarning: partnerEarning.totalEarning,
        earningAdjustment: partnerEarning.earningAdjustment,
        rootQualityIndicator: partnerEarning.rootQualityIndicator,
        finalized: partnerEarning.finalized.toString(),
        costPerProfile: partnerEarning.costPerProfile,
        profileToBindRatio: partnerEarning.profileToBindRatio,
        updatedAt: partnerEarning.updatedAt ? dayjs(partnerEarning.updatedAt).format('MMM D, YYYY') : '--',
      };
    });
  }, [partnerEarnings]);

  useEffect(() => {
    setFilters(partnerFilters
      .filter((x) => x.value?.length)
      .map((filter) => {
        return {
          ...filter,
          type: FILTER_TYPES.INCLUDES_SOME,
        };
      }));
  }, [partnerFilters]);

  const columns = useMemo(() => {
    const columnsNames = [
      {
        Header: 'Partner Earning Id',
        accessor: 'partnerEarningId',
      }, {
        Header: 'Partner Id',
        accessor: 'partnerId',
      }, {
        Header: 'Partner',
        accessor: 'partnerName',
      }, {
        Header: 'Partner Type',
        accessor: 'partnerType',
      }, {
        Header: 'Partner Type Id',
        accessor: 'partnerTypeId',
      }, {
        Header: 'Tier',
        accessor: 'tier',
      }, {
        Header: 'Earned Month',
        accessor: 'earnedMonth',
      }, {
        Header: 'Profile Count',
        accessor: 'profileCount',
      }, {
        Header: 'Test Drive Count',
        accessor: 'testDriveCount',
      }, {
        Header: 'Earned Event Count',
        accessor: 'attributedEarnedEventCount',
      }, {
        Header: 'Total Earning',
        accessor: 'totalEarning',
      }, {
        Header: 'Earning Adjustment',
        accessor: 'earningAdjustment',
      }, {
        Header: 'RQI',
        accessor: 'rootQualityIndicator',
      }, {
        Header: 'Finalized',
        accessor: 'finalized',
      }, {
        Header: 'CPP',
        accessor: 'costPerProfile',
      }, {
        Header: 'Profile to Bind Ratio',
        accessor: 'profileToBindRatio',
      }, {
        Header: 'Updated At',
        accessor: 'updatedAt',
      }];

    return columnsNames;
  }, []);

  const hiddenColumns = [
    'partnerEarningId',
    'partnerId',
    'partnerType',
    'partnerTypeId',
  ];

  return (
    <div css={styles.container}>
      {partnerEarnings.length !== 0 && (
        <Table
          columns={columns}
          csvExportable={true}
          data={data}
          filters={filters}
          initialState={{
            hiddenColumns,
          }}
          onRowSelect={onRowSelect}
          selectable={checkboxes}
          styleOverrides={tableStyles}
          useVirtualRows={true}
        />
      )
      }

      {partnerEarnings.length === 0 && (
        <div css={styles.noPartners}>
          <img
            alt={'info icon'}
            css={styles.infoIcon}
            src={infoIcon}
          />
          0 results found based on your filter criteria
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  noPartners: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: Colors.white(),
  },
  infoIcon: {
    paddingRight: 15,
    height: 25,
  },
});
const tableStyles = StyleSheet.create({
  tableHeaderGroup: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});
PartnerEarningsTable.propTypes = {
  checkboxes: PropTypes.bool,
  onRowSelect: PropTypes.func.isRequired,
  partnerEarnings: PropTypes.array,
  partnerFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
  })),
  partners: PropTypes.array,
};

export default React.memo(PartnerEarningsTable);
