import PrimaryButton from '@root/partners.admin.joinroot.com/src/components/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function PartnerEarningsAdjust({ onApply }) {
  const { trackClick } = useAnalytics('PARTNER_EARNINGS_ADJUST', false);

  const [earningAdjustmentAmount, setEarningAdjustmentAmount] = useState(0);

  const handleApply = () => {
    trackClick('APPLY', { earningAdjustmentAmount });
    onApply(earningAdjustmentAmount);
  };

  const handleAmountChange = (amount) => {
    setEarningAdjustmentAmount(amount);
  };

  return (
    <div css={styles.container}>
      <div css={styles.newAdjustmentLabel}>New Earning Adjustment</div>
      <div css={styles.item}>
        <div css={styles.inputLabel}>Adjustment Amount</div>
        <input
          css={styles.amountInput}
          data-testid={'adjustment-amount-input'}
          onChange={(e) => handleAmountChange(Number(e.target.value))}
          placeholder={'$ --.--'}
          step={0.01}
          type={'number'}
          value={earningAdjustmentAmount || ''}
        />
      </div>

      <PrimaryButton
        css={[styles.item, styles.button]}
        disabled={!earningAdjustmentAmount}
        onClick={handleApply}
      >
        Create Earning Adjustment(s)
      </PrimaryButton>
    </div>
  );
}

const styles = StyleSheet.create({
  button: {
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 'auto',
  },
  container: {
    paddingTop: 7,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 12,
    ...Responsive.lessThanXl({
      alignItems: 'stretch',
      maxWidth: 400,
      paddingLeft: 6,
      flexDirection: 'column',
    }),
  },
  item: {
    marginRight: 6,
    ...Responsive.lessThanXl({
      marginTop: 6,
      marginRight: 0,
    }),
  },
  newAdjustmentLabel: {
    color: Colors.gray50(),
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 24,
    marginRight: 40,
    ...Responsive.lessThanXl({
      padding: 10,
      marginLeft: 0,
    }),
  },
  eventButton: {
    paddingLeft: 14,
    paddingRight: 14,
    marginRight: 12,
    marginLeft: 6,
    marginTop: 'auto',
    height: 34,
    borderRadius: 3,
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    ':focus': {
      outline: 'none',
    },
    ...Responsive.lessThanXl({
      marginLeft: 'auto',
      marginTop: 6,
      width: '100%',
    }),
  },
  applyRuleButton: {
    right: 30,
    position: 'absolute',
    width: 176,
    marginTop: 9,
    ...Responsive.lessThanXl({
      marginTop: 6,
      right: 0,
      position: 'relative',
      width: '100%',
    }),
  },
  amountInput: {
    ...Responsive.lessThanXl({
      marginTop: 6,
      right: 0,
      position: 'relative',
      width: '100%',
    }),
  },
  inputLabel: {
    color: Colors.gray50(),
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 3,
  },
});

PartnerEarningsAdjust.propTypes = {
  onApply: PropTypes.func.isRequired,
};
