export default () => (
  <svg
    fill={'none'}
    height={'24'}
    viewBox={'0 0 24 24'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <circle
      cx={'12'}
      cy={'12'}
      fill={'#299C99'}
      r={'11'}
    />
    <path
      d={'M7 11.5L10.5 15L17.5 8'}
      stroke={'white'}
      strokeWidth={'2'}
    />
  </svg>
);
